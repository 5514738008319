import TokenHandler from './token-handler';
import NotificationsDom from '../dom/notifications-dom';
import axios from 'axios';

export default class HttpHelper {

	// Example POST method implementation:
	static async postData(url = '', data = {}) {
		// Default options are marked with *
		const response = axios({
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			url: url,
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json'
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
			validateStatus: (status) => status >= 200 && status < 500,
			data: JSON.stringify(data) // body data type must match "Content-Type" header
		});
		// return response?.json(); // parses JSON response into native JavaScript objects
		return response; // parses JSON response into native JavaScript objects
	}

	static async patchData(url = '', data = {}) {
		// Default options are marked with *
		const response = axios({
			method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
			url: url,
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + TokenHandler.getAccessToken()
                // 'Content-Type': 'application/x-www-form-urlencoded',
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
			validateStatus: (status) => status >= 200 && status < 500,
			data: JSON.stringify(data) // body data type must match "Content-Type" header
		});
		// return response?.json(); // parses JSON response into native JavaScript objects
		return response; // parses JSON response into native JavaScript objects
	}

	// static async fetchData(url) {
	//     return await fetch(url, {
	//         method: 'GET',
	//         headers: {
	//             'Content-Type': 'application/json',
	//             'Authorization': 'Bearer ' + TokenHandler.getAccessToken()
	//         }
	//     });
	// }

	static async fetch(url) {
        return axios({
            method: 'GET',
            url: url,
            validateStatus: (status) => status >= 200 && status < 500
        });
	}

	static async fetchData(url) {
	    const response = await axios({
            method: 'GET',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + TokenHandler.getAccessToken()
            },
            validateStatus: (status) => status >= 200 && status < 500
        });
        if (response?.status === 200) {
            return response;
        } else {
            await HttpHelper.handleResponse(response, window.location.href);
        }
	}

	static async fetchFileData(url) {
	    const response = await axios({
            method: 'GET',
            url: url,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + TokenHandler.getAccessToken()
            },
            validateStatus: (status) => status >= 200 && status < 500
        });
        if (response?.status === 200) {
            return response;
        } else if (response?.status === 404) {
            return { status: 404, message: 'Datei nicht gefunden' };
        } else {
            await HttpHelper.handleResponse(response);
        }
	}

	static async handleResponse(response, refreshUrl) {
		if (response?.status === 401 || response?.status === 403) {
			await TokenHandler.tryRefreshToken(refreshUrl);
		} else {
			if (response?.status >= 400 && response?.status <= 499) {
				NotificationsDom.renderCautionNotification(response?.status);
			}
			if (response?.status >= 500 && response?.status <= 599) {
				NotificationsDom.renderErrorNotification(response?.status);
			}
            window.location.href = '/app/error'

		}
	}

	static readCurrentHeaders(returnHeader) {
		const req = new XMLHttpRequest();
		req.open('GET', document.location, false);
		req.send(null);
		const headers = req.getAllResponseHeaders().replace(/\r\n/g, ' ').split(' ');

		if(returnHeader) {
			let header = '';
			headers.find((element, index ) => {
				if(element === returnHeader) {
					header = headers[index + 1];
				}
			});
			return header;
		} else {
			return headers;
		}
	}
}
