import HtmlHelper from '../utils/html-helper';

export default class NotificationsDom {

	constructor() {
	}

	static getNotificationArea() {
	    const notificationslist = document.querySelector('#notificationsArea>.notifications-list');
	    const notificationsCount = notificationslist.querySelectorAll('.p-notification__response').length;
	    if(!!notificationsCount >= 0) {
            setTimeout(function () {
                notificationslist.firstElementChild?.remove();
            }, 5000);
        }
	    if(notificationsCount >= 6) {
            notificationslist.firstElementChild?.remove();
        }
		return notificationslist;
	}
	static renderCautionNotification (message) {
		const notification = HtmlHelper.createElementFromHTML(`
        <div class="p-notification--information">
          <p class="p-notification__response" role="status">
            <span class="p-notification__status"></span>${message}
            <a href="#" class="p-notification__action">
                <i class="p-icon--close"></i>
            </a>
          </p>
        </div>
        `);
		NotificationsDom.notificationAddEventListener(notification);
		NotificationsDom.getNotificationArea().appendChild(notification);
	}
    static renderUniqueCautionNotification(message, uniqueUrl) {
        let render = true;
        NotificationsDom.getNotificationArea().childNodes?.forEach(child => {
            if (child.id === uniqueUrl) {
                render = false;
            }
        });
        if (!!render) {
            const notification = HtmlHelper.createElementFromHTML(`
                <div id="${uniqueUrl}" class="p-notification--information">
                  <p class="p-notification__response" role="status">
                    <span class="p-notification__status"></span>${message}
                    <a href="#" class="p-notification__action">
                        <i class="p-icon--close"></i>
                    </a>
                  </p>
                </div>
                `);
            NotificationsDom.notificationAddEventListener(notification);
            NotificationsDom.getNotificationArea().appendChild(notification);
        }
	}
	static renderErrorNotification (message) {
		const notification = HtmlHelper.createElementFromHTML(`
        <div class="p-notification--information">
          <p class="p-notification__response" role="status">
            <span class="p-notification__status"></span>${message}
            <a href="#" class="p-notification__action">
                <i class="p-icon--close"></i>
            </a>
          </p>
        </div>
        `);
		NotificationsDom.notificationAddEventListener(notification);
		NotificationsDom.getNotificationArea().appendChild(notification);
	}
	static renderPositivNotification (message) {
		const notification = HtmlHelper.createElementFromHTML(`
        <div class="p-notification--information">
          <p class="p-notification__response" role="status">
            <span class="p-notification__status"></span>${message}
            <a href="#" class="p-notification__action">
                <i class="p-icon--close"></i>
            </a>
          </p>
        </div>
        `);
		NotificationsDom.notificationAddEventListener(notification);
		NotificationsDom.getNotificationArea().appendChild(notification);
	}
	static renderInformationNotification (message) {
		const notification = HtmlHelper.createElementFromHTML(`
        <div class="p-notification--information">
          <p class="p-notification__response" role="status">
            <span class="p-notification__status"></span>${message}
            <a href="#" class="p-notification__action">
                <i class="p-icon--close"></i>
            </a>
          </p>
        </div>
        `);
		NotificationsDom.notificationAddEventListener(notification);
		NotificationsDom.getNotificationArea().appendChild(notification);
	}

	static notificationAddEventListener (notification) {
		notification.addEventListener('click', () => {
			notification.remove();
		});
    }
}
