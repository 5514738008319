import HtmlHelper from '../utils/html-helper';

export default class ReferenceModule {

	static manipulateHtml() {
		const elements = document.querySelectorAll('li');
		if(elements) {
			if(elements.length > 0) {
				elements.forEach(element => {
					if(ReferenceModule.keyToLanguage(element.innerHTML)) {
                        element.innerHTML = ReferenceModule.keyToLanguage(element.innerHTML);
                        if(element.innerHTML.includes('*')) {
                            element.innerHTML = element.innerHTML.replaceAll('*', '');
							HtmlHelper.markAsSecret(element, 'self');
						} else if(
						    // element.innerHTML === 'Zeugnis' || // https://beige-unimaginable-idols.awork.io/tasks/a0652154-cff9-eb11-b563-dc984023d47e/details
                            element.innerHTML === 'Beruf') {
							HtmlHelper.markAsSecret(element, 'self');
						}
                    }
				});
			}
		}
	}

	static keyToLanguage(key) {
	    // @deprictated TODO remove asterisk
	    let secret = key.includes('*');
        if(secret) {
            key = key.replaceAll('*', '');
            secret = '*';
        } else {
            secret = '';
        }
		switch (key) {
            case 'Degree' : return 'Abschluss' + secret;
            case 'DegreeType' : return 'Abschlusstyp' + secret;
            case 'ResponsibleOffice' : return 'Zuständige Stelle in Deutschland' + secret;
            case 'Occupation' : return 'Beruf' + secret;
            case 'BvChapter' : return 'Kapitel des Bildungswesens' + secret;
            case 'Document' : return 'Dokument' + secret;
            case 'Institution' : return 'Institution' + secret;
            case 'Expertise' : return 'Mustergutachten' + secret;
            case 'Certificate' : return 'Zeugnis' + secret;
            case 'Recognitionoffice' : return 'Zuständige Stelle im Ausland' + secret;
            default : return null;
		}
	}

	static keyToModalMethod(key) {
		let methodName = '';
		// TODO remove depricated asterisk
		switch (key.replaceAll('*', '')) {
            case 'Degree' : return 'openDegreeModal';
            case 'DegreeType' : return 'openDegreeTypeInformationModal';
            case 'Occupation' : return 'openOccupationModal';
            case 'Expertise' : return 'openExpertiseModal';
            case 'BvChapter' : return 'openBvChapterModal';
            case 'Institution' : return 'openInstitutionModal';
            case 'Certificate' : return 'openCertificateWithoutChildrenModal';
            case 'Document' : return 'openDocumentModal';
            case 'Recognitionoffice' : return 'openRecognitionOfficeModal';
            case 'ResponsibleOffice' : return 'openResponsibleOfficeModal';
		}
		return methodName;
	}

	static parseReference(objectReference) {
		const keys = Object.keys(objectReference);
		const references = [];

		if(Array.isArray(keys) && keys.length > 0) {
			keys.forEach(key => {
				if (Array.isArray(objectReference[key])) {
					objectReference[key].forEach(entry => {
						references.push({ key:key,object:entry });
					});
				}
			});
		}

		return references;
	}

	static getReferenceObject(objectReference) {
		if(objectReference) {
			const references = { in: objectReference.in, out: objectReference.out };

			if(references.in && JSON.stringify(references.in) !== '{}') {
				references.in = ReferenceModule.parseReference(references.in);
			}
			if(references.out && JSON.stringify(references.out) !== '{}') {
				references.out = ReferenceModule.parseReference(references.out);
			}

			return references;
		}
	}

	static reorderReferenceArray(arrayWithUnsortedReferences, arrayWithUnsortedReferenceFunctionNames) {
	    const arrayWithSortedReferences = [];
	    const arrayWithSortedReferenceFunctionNames = [];
	    if((Array.isArray(arrayWithUnsortedReferences) && arrayWithUnsortedReferences.length > 0)
	        && (Array.isArray(arrayWithUnsortedReferenceFunctionNames) && arrayWithUnsortedReferenceFunctionNames.length > 0)) {
            arrayWithUnsortedReferences.forEach((reference, index) => {
                if(reference.key === 'Degree') {
                    arrayWithSortedReferences.push(reference);
                    arrayWithSortedReferenceFunctionNames.push(arrayWithUnsortedReferenceFunctionNames[index])
                }
            });
            arrayWithUnsortedReferences.forEach((reference, index) => {
                if(reference.key === 'DegreeType') {
                    arrayWithSortedReferences.push(reference);
                    arrayWithSortedReferenceFunctionNames.push(arrayWithUnsortedReferenceFunctionNames[index])
                }
            });
            arrayWithUnsortedReferences.forEach((reference, index) => {
                if(reference.key === 'Occupation') {
                    arrayWithSortedReferences.push(reference);
                    arrayWithSortedReferenceFunctionNames.push(arrayWithUnsortedReferenceFunctionNames[index])
                }
            });
            arrayWithUnsortedReferences.forEach((reference, index) => {
                if(reference.key === 'Expertise') {
                    arrayWithSortedReferences.push(reference);
                    arrayWithSortedReferenceFunctionNames.push(arrayWithUnsortedReferenceFunctionNames[index])
                }
            });
            arrayWithUnsortedReferences.forEach((reference, index) => {
                if(reference.key === 'BvChapter') {
                    arrayWithSortedReferences.push(reference);
                    arrayWithSortedReferenceFunctionNames.push(arrayWithUnsortedReferenceFunctionNames[index])
                }
            });
            arrayWithUnsortedReferences.forEach((reference, index) => {
                if(reference.key === 'Institution') {
                    arrayWithSortedReferences.push(reference);
                    arrayWithSortedReferenceFunctionNames.push(arrayWithUnsortedReferenceFunctionNames[index])
                }
            });
            arrayWithUnsortedReferences.forEach((reference, index) => {
                if(reference.key === 'Certificate') {
                    arrayWithSortedReferences.push(reference);
                    arrayWithSortedReferenceFunctionNames.push(arrayWithUnsortedReferenceFunctionNames[index])
                }
            });
            arrayWithUnsortedReferences.forEach((reference, index) => {
                if(reference.key === 'Document') {
                    arrayWithSortedReferences.push(reference);
                    arrayWithSortedReferenceFunctionNames.push(arrayWithUnsortedReferenceFunctionNames[index])
                }
            });
            arrayWithUnsortedReferences.forEach((reference, index) => {
                if(reference.key === 'Recognitionoffice') {
                    arrayWithSortedReferences.push(reference);
                    arrayWithSortedReferenceFunctionNames.push(arrayWithUnsortedReferenceFunctionNames[index])
                }
            });
            arrayWithUnsortedReferences.forEach((reference, index) => {
                if(reference.key === 'ResponsibleOffice') {
                    arrayWithSortedReferences.push(reference);
                    arrayWithSortedReferenceFunctionNames.push(arrayWithUnsortedReferenceFunctionNames[index])
                }
            });
        }
        return [ arrayWithSortedReferences, arrayWithSortedReferenceFunctionNames ];
    }
}
