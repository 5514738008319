import DataTableDom from '../dom/data-table-dom';
import NotificationsDom from '../dom/notifications-dom';
import HttpHelper from '../utils/http-helper';
import Spinner from '../dom/spinner';

export default class BvChapterModule {

	constructor(countriesModule, apiFilter, countryRequired = false) {
		this.countriesModule = countriesModule;
		this.apiFilter = apiFilter;
        this.subHeadlineTitle = apiFilter === 'BV-Behördeninfo' ? 'Behördeninformationen' : apiFilter === 'BV-Notenberechnung' ? 'Notenberechnung' : null;
		this.countryRequired = countryRequired;
		this.sortOverride = {
			field: 'country.nameShort',
			direction: 1
		};
		this.dataTable = null;
		this.totalResults = '';
    }

	initialise() {
		this.dataTable = new DataTableDom(this, this.sortOverride);
        this.dataTable.limit = 10;
	}

	async getData () {
        Spinner.show(this);


        const searchInput = document.getElementById('tableSearchInput');

        if (this.countriesModule.countriesSelected.size === 0 && searchInput.value === "") {
            Spinner.hide(this);
            document.querySelector('#missingCountry').classList.remove('d-none')
            document.querySelector('#emptyDatasetRow').classList.add('d-none')
            document.querySelector('#info-text')?.classList.add('d-none')
            document.querySelector('#dataRow').classList.add('d-none')
            if (document.querySelector('#sub-headline')) {
                document.querySelector('#sub-headline').innerHTML = '';
            }

            return;
        } else {
            document.querySelector('#missingCountry')?.classList.add('d-none')
        }
        const staticFilter = { type : this.apiFilter };

        let filterQuery = {
            $and: []
        };
        if (this.countriesModule.countriesSelected.size === 0) {
            filterQuery = staticFilter;
        } else {
            filterQuery['$and'] = [ staticFilter, { $or: Array.from(this.countriesModule.countriesSelected).map((countryId) => ({ 'country': { $oid: countryId } })) } ];
        }

        const response = await HttpHelper.fetchData(`${location.origin}/api/public/bv-chapter?sort={"${this.dataTable.sort.field}":${this.dataTable.sort.direction}}&filter=${JSON.stringify(filterQuery)}&limit=${this.dataTable.limit}&offset=${this.dataTable.offset}&search=${this.dataTable.searchText}&reference=true`);
        document.querySelector('#emptyDatasetRow').classList.add('d-none')

        document.querySelector('#dataRow').classList.add('d-none')
        document.querySelector('#missingCountry').classList.add('d-none')

        this.totalResults = response?.headers['total-results'] ?? '0';
        this.dataTable.totalResults = this.totalResults;
        if (this.totalResults === '0') {
            NotificationsDom.renderInformationNotification('Für diese Auswahl wurden keine Datensätze gefunden!');
            document.querySelector('#info-text')?.classList.remove('d-none');
            this.dataTable.emptyDatasetRow.classList.remove('d-none');
                        if(this.dataTable.tableSearchInput.value === '') {
                this.dataTable.dataRow.classList.add('d-none');
            }
        } else {
            if (this.subHeadlineTitle && document.querySelector('#sub-headline')) {
                document.querySelector('#sub-headline').innerHTML = `${this.subHeadlineTitle} in ${this.countriesModule.lastCountryNameSelected}`;
            }
            this.dataTable.emptyDatasetRow.classList.add('d-none');
            this.dataTable.dataRow.classList.remove('d-none');
        }
        const responseData = response?.data;
        const renderData = [ { context: 'bv-chapter' } ];
        await this.dataTable.renderTableData(responseData, this.dataTable.tableSearchInput, renderData);
        this.dataTable.paginator.renderPagination(this.dataTable);

        Spinner.hide(this);
	}

	static async getId(id) {
		const response = await HttpHelper.fetchData(`${location.origin}/api/public/bv-chapter/${id}?reference=true`);
		return response?.data;
	}

	loadOptions () {
		if (!this.countriesModule.countriesSelected.size || this.countriesModule.countriesSelected.size === 0) {
			return;
		}

	}
}
