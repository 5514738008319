
import DataTableDom from '../dom/data-table-dom';
import NotificationsDom from '../dom/notifications-dom';
import HttpHelper from '../utils/http-helper';
import Spinner from '../dom/spinner';

export default class NewsModule {

	constructor() {
		this.years = [];
		this.yearSelected = null;
		this.yearChanged = true;
		this.yearSelect = document.querySelector('#yearSelect');
        this.yearResetButton = document.querySelector('#yearResetButton');

		this.sortOverride = {
			field: 'date',
			direction: -1
		};

		this.dataTable = null;
		this.totalResults = '';
	}

	initialise() {
		this.dataTable = new DataTableDom(this, this.sortOverride);
		this.addEventListener();
	}

	addEventListener() {
		this.yearSelect.addEventListener('change', () => {
			this.yearSelected = this.yearSelect.value;
			this.yearChanged = true;
            this.yearResetButton.removeAttribute('disabled')
            if (this.dataTable) this.dataTable.page = 1;
            if (this.dataTable) this.dataTable.offset = 0;
            this.getData().then(() => this.yearChanged = false);
		});
        this.yearResetButton.addEventListener('click', () => {
            this.yearSelect.value = ''
            this.yearSelected = null;
			this.yearChanged = true;
            this.yearResetButton.setAttribute('disabled', 'true')
            if (this.dataTable) this.dataTable.page = 1;
            if (this.dataTable) this.dataTable.offset = 0;
            this.getData().then();
        })
	}

	async getData () {
        Spinner.show(this);

        const year = this.yearSelected === null ? '' : this.yearSelected;
        let filterQuery = {};

        const response = await HttpHelper.fetchData(`${location.origin}/api/public/news?sort={"${this.dataTable.sort.field}":${this.dataTable.sort.direction}}&filter=${JSON.stringify(filterQuery)}&year=${year}&limit=${this.dataTable.limit}&offset=${this.dataTable.offset}&search=${this.dataTable.searchText}`);

        this.totalResults = response?.headers['total-results'] ?? '0';
        this.dataTable.totalResults = this.totalResults;
        if (this.totalResults === '0') {
            NotificationsDom.renderInformationNotification('Für diese Auswahl wurden keine Datensätze gefunden!');
            this.dataTable.emptyDatasetRow.classList.remove('d-none');
            if (this.dataTable.tableSearchInput.value === '') {
                this.dataTable.dataRow.classList.add('d-none');
            }
        } else {
            this.dataTable.emptyDatasetRow.classList.add('d-none');
            this.dataTable.dataRow.classList.remove('d-none');
        }
        const responseData = response?.data;
        if (Array.isArray(responseData)) {
            this.years = [{
                label: 'Alle Jahre',
                value: ''
            }, ...(responseData?.[0]?.map?.((year) => ({label: year.toString(), value: year})) ?? [])];
            this.setAllYearsOption();

            const renderData = [{context: 'news'}];
            this.dataTable.renderTableData(responseData[1], this.dataTable.tableSearchInput, renderData);
            this.dataTable.paginator.renderPagination(this.dataTable);
        }

        Spinner.hide(this);
	}

	static async getId(id) {
		const response = await HttpHelper.fetchData(`${location.origin}/api/public/news/${id}`);
		return response?.data;
	}

	setAllYearsOption() {
		if (this.years.length > 0 && this.yearSelected == null) {
            this.yearSelect.innerHTML = ''
			this.years.forEach((year) => {
				const newOption = document.createElement('OPTION');
				newOption.innerHTML = year.label;
				newOption.value = year.value;
				this.yearSelect.appendChild(newOption);
			});

			this.yearSelect.value = '';
			this.yearSelected = this.yearSelect.value;

			this.yearSelect.removeAttribute('disabled');
		}
	}

}
