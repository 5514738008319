
import DataTableDom from '../dom/data-table-dom';
import NotificationsDom from '../dom/notifications-dom';
import FaqgroupModule from './faqgroup-module';
import HttpHelper from '../utils/http-helper';
import Spinner from '../dom/spinner';

export default class FaqModule {

	constructor() {
		this.faqgroup = new FaqgroupModule();

		this.dataTable = null;
		this.totalResults = '';

		this.groupSelect = document.querySelector('#groupSelect')
		this.groupSelectResetButton = document.querySelector('#groupSelectResetButton')
		this.categorySelect = document.querySelector('#categorySelect')
		this.categorySelectResetButton = document.querySelector('#categorySelectResetButton')
	}


    addEventListener() {
        this.groupSelectResetButton.addEventListener('click', () => {
            this.faqgroup.groupSelected = null;
            this.groupSelect.value = '';
            this.categorySelect.value = '';
            this.faqgroup.groupSelected = null;
            this.faqgroup.categorySelected = null;
            this.categorySelectResetButton.setAttribute('disabled', 'true')
            this.groupSelectResetButton.setAttribute('disabled', 'true')
            // this.getData().then()
            this.faqgroup.groupChanged = true;
            this.loadOptions().then()
        })
        this.categorySelectResetButton.addEventListener('click', () => {
            this.faqgroup.categorySelected = null;
            this.categorySelect.value = '';
            this.faqgroup.categorySelected = null;
            this.categorySelectResetButton.setAttribute('disabled', 'true')
            // this.getData().then()
            this.loadOptions().then()
        })
    }

	initialise() {
		this.dataTable = new DataTableDom(this, {
            field: 'category.group.nameShort',
            direction: 1
        });
        this.addEventListener();
	}

	async getData () {
        Spinner.show(this);

        const searchInput = document.getElementById('tableSearchInput');

        if (this.groupSelect.value === "" && this.categorySelect.value === "" && searchInput.value === "") {
            Spinner.hide(this);
            document.querySelector('#missingCountry').classList.remove('d-none')
            document.querySelector('#emptyDatasetRow').classList.add('d-none')
            document.querySelector('#info-text')?.classList.add('d-none')
            document.querySelector('#dataRow').classList.add('d-none')
            if (document.querySelector('#sub-headline')) document.querySelector('#sub-headline').innerHTML = '';
            return;
        }

        let filterQuery = {
            $and: [{}]
        };

        if (this.faqgroup.groupSelected) {
            filterQuery['$and'].push({'category.group': {$oid: this.faqgroup.groupSelected}});
        }

        if (this.faqgroup.categorySelected) {
            filterQuery['$and'].push({'category': {$oid: this.faqgroup.categorySelected}});
        }

        const response = await HttpHelper.fetchData(`${location.origin}/api/public/faq?sort={"${this.dataTable.sort.field}":${this.dataTable.sort.direction}}&filter=${JSON.stringify(filterQuery)}&limit=${this.dataTable.limit}&offset=${this.dataTable.offset}&search=${this.dataTable.searchText}`);
        document.querySelector('#emptyDatasetRow').classList.add('d-none')

        document.querySelector('#dataRow').classList.add('d-none')
        document.querySelector('#missingCountry').classList.add('d-none')

        this.totalResults = response?.headers['total-results'] ?? '0';
        this.dataTable.totalResults = this.totalResults;
        if (this.totalResults === '0') {
            NotificationsDom.renderInformationNotification('Für diese Auswahl wurden keine Datensätze gefunden!');
            document.querySelector('#info-text')?.classList.remove('d-none');
            this.dataTable.emptyDatasetRow.classList.remove('d-none');
            if (this.dataTable.tableSearchInput.value === '') {
                this.dataTable.dataRow.classList.add('d-none');
            }
        } else {
            this.dataTable.emptyDatasetRow.classList.add('d-none');
            this.dataTable.dataRow.classList.remove('d-none');
        }
        const responseData = response?.data;
        const renderData = [{context: 'faq'}];
        this.dataTable.renderTableData(responseData, this.dataTable.tableSearchInput, renderData);
        this.dataTable.paginator.renderPagination(this.dataTable);

        Spinner.hide(this);
	}

	static async getId(id) {
		const response = await HttpHelper.fetchData(`${location.origin}/api/public/news/${id}`);
		return response?.data;
	}

	async loadOptions () {
		await this.faqgroup.getAllData().then();
	}
}
