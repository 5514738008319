import HttpHelper from './http-helper';
import NotificationsDom from '../dom/notifications-dom';
import HtmlHelper from './html-helper';

export default class TokenHandler {

    static setUsername(username) {
        sessionStorage.setItem('username', username);
    }

    static getUsername() {
        return sessionStorage.getItem('username');
    }

    static setUserRights(userRights) {
        sessionStorage.setItem('isGfg', userRights.isGfg);
        sessionStorage.setItem('isAuthority', userRights.isAuthority);
        sessionStorage.setItem('isKmk', userRights.isKmk);
    }

    static isGfg() {
        return JSON.parse(sessionStorage.getItem('isGfg'));

    }
    static isKmk() {
        return JSON.parse(sessionStorage.getItem('isKmk'));
    }

    static isAuthority() {
        return JSON.parse(sessionStorage.getItem('isAuthority'));
    }

    static isLoggedIn() {
        return !!(sessionStorage.getItem('isKmk') || sessionStorage.getItem('isGfg') || sessionStorage.getItem('isAuthority'));
    }

    static setAccessToken(accessToken) {
        localStorage.setItem('accessToken', accessToken);
    }

    static getAccessToken() {
        return localStorage.getItem('accessToken');
    }

    static setRefreshToken(refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
    }

    static getRefreshToken() {
        return localStorage.getItem('refreshToken');
    }

    static clearStorage() {
        sessionStorage.clear();
        Object.keys(localStorage)
            .filter((key) => key !== '__cc')
            .forEach((key) => {
                localStorage.removeItem(key);
        })

        TokenHandler.validateLoginState();
    }

    static async validateLoginState() {
        const loginHeader = document.querySelector('#loginHeader');

        if(TokenHandler.getAccessToken()) {
        	await HttpHelper.fetchData(`${location.origin}/api/public/auth/is-authenticated/${TokenHandler.getAccessToken()}`).then(async result => {

                if (result?.status === 200) {
                    TokenHandler.handleUserRights();
                    if (TokenHandler.getUsername()) {
                        TokenHandler.setLoggedIn(loginHeader);
                    } else if (TokenHandler.getRefreshToken()) {
                        if (!this.isRefreshing)
                            await TokenHandler.tryRefreshToken()
                    } else {
                        TokenHandler.setLoggedOut(loginHeader);
                    }
                }
                else if (result?.status === 401) {
                    if (TokenHandler.getRefreshToken()) {
                        if (!this.isRefreshing)
                            await TokenHandler.tryRefreshToken()
                    } else {
                        TokenHandler.setLoggedOut(loginHeader);
                    }
                } else {
                    TokenHandler.setLoggedOut(loginHeader);
                }
            });
        }

        // if(loginHeader.dataset.loggedIn === 'true') {
        // 	loginHeader.addEventListener('click', () => {
        // 		TokenHandler.clearStorage();
        // 	});
        // } else {
        // 	loginHeader.removeEventListener('click', null, false);
        // }
    }

    static setLoggedIn(loginHeader) {
        loginHeader.querySelector('#user').innerHTML = `Angemeldet</i>`;
        loginHeader.dataset.loggedIn = true;
        loginHeader.querySelector('#profile').classList.remove('d-none');
        loginHeader.querySelector('#signInOut').classList.remove('d-none');
        loginHeader.querySelector('#accountMenu').classList.remove('accountNoMenu');
        loginHeader.querySelector('#accountMenu').classList.add('accountMenu');
        const signInOut = loginHeader.querySelector('#signInOut > a');
        if (!signInOut.getAttribute('event')) {
            signInOut.addEventListener('click', () => {
                if (!!confirm("Wollen Sie sich wirklich abmelden?")) {
                    HttpHelper.fetchData(`${location.origin}/api/public/auth/logout`).then(() => {
                        TokenHandler.clearStorage();
                        window.location.replace(`${location.origin}/db/login`);
                    })
                }
            });
        }
        signInOut.setAttribute("event", true);
        loginHeader.querySelector('#passwordResetMenuItem').classList.remove('d-none');
    }

    static setLoggedOut(loginHeader) {
        loginHeader.dataset.loggedIn = false;
        loginHeader.querySelector('#profile').classList.add('d-none')
        loginHeader.querySelector('#passwordResetMenuItem').classList.add('d-none');
        loginHeader.querySelector('#signInOut').classList.add('d-none');
        loginHeader.querySelector('#accountMenu').classList.remove('accountMenu');
        loginHeader.querySelector('#accountMenu').classList.add('accountNoMenu');
        loginHeader.querySelector('#user').addEventListener('click', (event) => {
            if (!TokenHandler.getUsername()) {
                window.location.replace(`${location.origin}/db/login`);
            }
        });
    }

    static handleUserRights() {
        const occupationsAside = document.querySelector('#occupationsAside'); //Berufsabschlüsse
        const expertiseAside = document.querySelector('#expertiseAside'); //Mustergutachten
        const certificatesAside = document.querySelector('#certificatesAside'); //Schulabschlüsse mit Hochschulzugang
        const bvChapterGradeEvaluationTab = document.querySelector('#bv-chapter-grade-evaluation-tab'); //Schulabschlüsse mit Hochschulzugang -> Notenberechnung
        const bvChapterAuthorityInformationTab = document.querySelector('#bv-chapter-authority-information-tab'); //Schulabschlüsse mit Hochschulzugang -> Behördeninformation
        const certificatesMsaAside = document.querySelector('#certificatesMsaAside'); //Schulabschlüsse ohne Hochschulzugang
        const redirectToHeader = document.querySelector('#redirectToHeader'); //GfG Weiterleitung Header
        const redirectToAside = document.querySelector('#redirectToAside'); //GfG Weiterleitung Aside
        const documentsCurriculaGfgAside = document.querySelector('#documentsCurriculaGfgAside'); //Curricula GfG
        const expertiseGfgTab = document.querySelector('#expertiseGfgTab')

        if (!!TokenHandler.isAuthority() ) {
            bvChapterGradeEvaluationTab?.classList.remove('d-none');
            bvChapterAuthorityInformationTab?.classList.remove('d-none');
        } else {
            bvChapterGradeEvaluationTab?.classList.add('d-none');
            bvChapterAuthorityInformationTab?.classList.add('d-none');
        }
        if (!!TokenHandler.isGfg()) {
            expertiseGfgTab?.classList.remove('d-none');
            redirectToHeader?.classList.remove('d-none');
            redirectToAside?.classList.remove('d-none');
        }

        //https://beige-unimaginable-idols.awork.io/tasks/5690015e-ffc1-ec11-997e-38563d6e6dce/details
        // TODO undo this changes in following files:
        //  'token-handler.js'
        //  'documents-curricula-gfg.controller.ts'
        //  'documents-curricula-gfg.js'
        //  'documents-curricula-gfg-information.js'
        if (!!TokenHandler.isKmk()) {
            documentsCurriculaGfgAside?.classList.remove('d-none');
            HtmlHelper.markAsSecret(documentsCurriculaGfgAside, 'child');
        }
        if (!!TokenHandler.isAuthority() || !!TokenHandler.isGfg()) {
            if(!!TokenHandler.isAuthority() || !!TokenHandler.isGfg()){
                HtmlHelper.markAsSecret(occupationsAside, 'child');
            }
        }
        if (!!TokenHandler.isAuthority() && TokenHandler.isKmk()) {

            certificatesMsaAside?.classList.remove('d-none');
            HtmlHelper.markAsSecret(certificatesMsaAside, 'child');
        }

        if (!!TokenHandler.isAuthority()) {
            HtmlHelper.markAsSecret(expertiseAside, 'child');
            expertiseAside?.classList.remove('d-none');
            HtmlHelper.markAsSecret(certificatesAside, 'child');
        }
    }

    static saveTokenData(tokenData, refreshing) {
        if (tokenData['access_token']) {
            TokenHandler.setAccessToken(tokenData['access_token']);
        }
        if (tokenData['refresh_token']) {
            TokenHandler.setRefreshToken(tokenData['refresh_token']);
        }
        if (tokenData['username']) {
            if (!refreshing) {
                NotificationsDom.renderPositivNotification('Login erfolgreich!');
            }
            TokenHandler.setUsername(tokenData['username']);
            TokenHandler.setUserRights(tokenData['userRights']);
            TokenHandler.validateLoginState();
        } else {
            NotificationsDom.renderCautionNotification('Ihre Zugangsdaten sind falsch!');
        }
    }

    static async tryRefreshToken(refreshUrl) {
        if (!!this.isRefreshing) return;
        if (!!TokenHandler.getRefreshToken()) {
            this.isRefreshing = true
            const response = await HttpHelper.fetch(`${location.origin}/api/public/auth/refresh/${TokenHandler.getRefreshToken()}`);
            const responseData = response?.data;
            if (response?.status === 200) {
                TokenHandler.clearStorage();
                TokenHandler.saveTokenData(responseData, this.isRefreshing);
                TokenHandler.handleUserRights();
                this.isRefreshing = false;
                if (!!refreshUrl && !refreshUrl.includes('/api/')) {
                    NotificationsDom.renderUniqueCautionNotification('Ihre Sitzung ist abgelaufen und wird in 5 Sekunden erneuert...', refreshUrl);
                    const timeout = setTimeout(function() {
                        const url = `${!refreshUrl.includes(location.origin) || refreshUrl.includes('/cms/*') ?  location.origin : ''}`;
                        const hasValidUrlProtocol = (url) =>
                            [ 'http://', 'https://', 'ftp://' ].some(protocol => url.startsWith(protocol));
                        window.location.replace(`${hasValidUrlProtocol ? '' : `${url.startsWith('/') ? '' : '/'}` }${url}`);
                    }, 5000);
                }
            }
            if (response?.status === 401 || response?.status === 403) {
                TokenHandler.clearStorage();
                window.location.replace(`${location.origin}/db/login?expired`);
            }
        } else {
            TokenHandler.clearStorage();
        }
    }
}

