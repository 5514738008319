import DataTableDom from '../dom/data-table-dom';
import NotificationsDom from '../dom/notifications-dom';
import HttpHelper from '../utils/http-helper';
import Spinner from '../dom/spinner';

export default class DocumentsCurriculaGfgModule {

    constructor(countriesModule) {
        this.countriesModule = countriesModule;

        this.dataTable = null;
        this.totalResults = '';

        this.degreesGroupSelect = document.querySelector('#degreesGroupSelect');
        this.degreesGroupSelectResetButton = document.querySelector('#degreesGroupSelectResetButton');
        this.degreeSelected = null;

        this.institutionsGroupSelect = document.querySelector('#institutionsGroupSelect');
        this.institutionsGroupSelectResetButton = document.querySelector('#institutionsGroupSelectResetButton');
        this.institutionSelected = null;

        this.occupationsGroupSelect = document.querySelector('#occupationsGroupSelect');
        this.occupationsGroupSelectResetButton = document.querySelector('#occupationsGroupSelectResetButton');
        this.occupationSelected = null;

        this.sortOverride = {
            field: 'validUntilYear',
            direction: -1
        };
    }

    initialise() {
        this.dataTable = new DataTableDom(this, this.sortOverride);
        this.addEventListener();
    }

    addEventListener() {
        this.degreesGroupSelect.addEventListener('change', () => {
            this.degreeSelected = this.degreesGroupSelect.value;
            if (this.degreeSelected === '') {
                this.degreesGroupSelectResetButton.setAttribute('disabled', 'true');
            } else {
                this.degreesGroupSelectResetButton.removeAttribute('disabled');
            }
            this.resetTable();
        });
        this.degreesGroupSelectResetButton.addEventListener('click', () => {
            this.degreeSelected = null;
            this.resetTable();
        });
        this.institutionsGroupSelect.addEventListener('change', () => {
            this.institutionSelected = this.institutionsGroupSelect.value;
            if (this.institutionSelected === '') {
                this.institutionsGroupSelectResetButton.setAttribute('disabled', 'true');
            } else {
                this.institutionsGroupSelectResetButton.removeAttribute('disabled');
            }
            this.resetTable();
        });
        this.institutionsGroupSelectResetButton.addEventListener('click', () => {
            this.institutionSelected = null;
            this.resetTable();
        });
        this.occupationsGroupSelect.addEventListener('change', () => {
            this.occupationSelected = this.occupationsGroupSelect.value;
            if (this.occupationSelected === '') {
                this.occupationsGroupSelectResetButton.setAttribute('disabled', 'true');
            } else {
                this.occupationsGroupSelectResetButton.removeAttribute('disabled');
            }
            this.resetTable();
        });
        this.occupationsGroupSelectResetButton.addEventListener('click', () => {
            this.occupationSelected = null;
            this.resetTable();
        });
    }

    resetTable() {
        if (this.dataTable) this.dataTable.page = 1;
        if (this.dataTable) this.dataTable.offset = 0;
        this.getData().then();
    }

    resetSelect() {
        this.degreeSelected = null;
        this.institutionSelected = null;
        this.occupationSelected = null;
    }

    async getData() {
        Spinner.show(this);

        let filterQuery = {};
        let referenceFilterQuery = {
            Degree: null,
            Institution: null,
            Occupation: null
        };
        if (this.countriesModule.countriesSelected.size === 0) {
            filterQuery = {};
        } else {
            filterQuery = { $or: Array.from(this.countriesModule.countriesSelected).map((countryId) => ({ 'country': { $oid: countryId } })) };

            if (this.degreeSelected !== null) {
                referenceFilterQuery.Degree = this.degreeSelected;
            }
            if (this.institutionSelected !== null) {
                referenceFilterQuery.Institution = this.institutionSelected;
            }
            if (this.occupationSelected !== null) {
                referenceFilterQuery.Occupation = this.occupationSelected;
            }
        }
        const response = await HttpHelper.fetchData(`${location.origin}/api/public/documents-curricula-gfg?sort={"${this.dataTable.sort.field}":${this.dataTable.sort.direction}}&filter=${JSON.stringify(filterQuery)}&limit=${this.dataTable.limit}&offset=${this.dataTable.offset}&search=${this.dataTable.searchText}&referenceFilter=${JSON.stringify(referenceFilterQuery)}`);

        this.totalResults = response?.headers['total-results'] ?? '0';
        this.dataTable.totalResults = this.totalResults;
        if (this.totalResults === '0') {
            NotificationsDom.renderInformationNotification('Für diese Auswahl wurden keine Datensätze gefunden!');
            this.dataTable.emptyDatasetRow.classList.remove('d-none');
                        if(this.dataTable.tableSearchInput.value === '') {
                this.dataTable.dataRow.classList.add('d-none');
            }
        } else {
            this.dataTable.emptyDatasetRow.classList.add('d-none');
            this.dataTable.dataRow.classList.remove('d-none');
        }
        const responseData = await response?.data;
        const renderData = [ { context: 'documents-curricula-gfg' } ];
        if (!!responseData) {
            this.setDegrees(responseData.degrees);
            this.setInstitutions(responseData.institutions);
            this.setOccupations(responseData.occupations);
        }
        this.dataTable.renderTableData(responseData?.documents, this.dataTable.tableSearchInput, renderData);
        this.dataTable.paginator.renderPagination(this.dataTable);

        Spinner.hide(this);
    }

    static async getId(id) {
        const response = await HttpHelper.fetchData(`${location.origin}/api/public/documents-curricula-gfg/${id}?reference=true`);
        return response?.data;
    }

    setAllDegreeOption() {
        this.degreesGroupSelect.value = '';
        this.degreesGroupSelect.innerHTML = null;
        // this.degreeSelected = null;
        this.degreesGroupSelectResetButton.setAttribute('disabled', 'true');
        if (this.countriesModule.countriesSelected.size === 0 || this.countriesModule.countriesSelected.size === 0) {
            const defaultOption = document.createElement('OPTION');
            defaultOption.innerHTML = 'Bitte wählen Sie zunächst ein Land';
            defaultOption.value = '';
            this.degreesGroupSelect.appendChild(defaultOption);
            this.degreesGroupSelect.setAttribute('disabled', 'true');
        } else {
            const defaultOption = document.createElement('OPTION');
            defaultOption.innerHTML = 'Alle Abschlüsse';
            defaultOption.value = '';
            this.degreesGroupSelect.appendChild(defaultOption);
            this.degreesGroupSelect.value = '';
            this.degreesGroupSelect.removeAttribute('disabled');
        }
    }

    setDegrees(degrees) {
        this.setAllDegreeOption();
        if (degrees.length > 0) {
            degrees.forEach((degree) => {
                const newOption = document.createElement('OPTION');
                newOption.innerHTML = degree.nameGerman;
                newOption.value = degree._id;
                this.degreesGroupSelect.appendChild(newOption);
            });
        }
        if (!!this.degreeSelected) {
            this.degreesGroupSelect.value = this.degreeSelected;
            this.degreesGroupSelectResetButton.removeAttribute('disabled');
        }
    }

    setAllInstitutionOption() {
        this.institutionsGroupSelect.value = '';
        this.institutionsGroupSelect.innerHTML = null;
        // this.institutionSelected = null;
        this.institutionsGroupSelectResetButton.setAttribute('disabled', 'true');
        if (this.countriesModule.countriesSelected.size === 0 || this.countriesModule.countriesSelected.size === 0) {
            const defaultOption = document.createElement('OPTION');
            defaultOption.innerHTML = 'Bitte wählen Sie zunächst ein Land';
            defaultOption.value = '';
            this.institutionsGroupSelect.appendChild(defaultOption);
            this.institutionsGroupSelect.setAttribute('disabled', 'true');
        } else {
            const defaultOption = document.createElement('OPTION');
            defaultOption.innerHTML = 'Alle Institutionen';
            defaultOption.value = '';
            this.institutionsGroupSelect.appendChild(defaultOption);
            this.institutionsGroupSelect.value = '';
            this.institutionsGroupSelect.removeAttribute('disabled');
        }
    }

    setInstitutions(institutions) {
        this.setAllInstitutionOption();
        if (institutions.length > 0) {
            institutions.forEach((institution) => {
                const newOption = document.createElement('OPTION');
                newOption.innerHTML = institution.nameGerman;
                newOption.value = institution._id;
                this.institutionsGroupSelect.appendChild(newOption);
            });
        }
        if (!!this.institutionSelected) {
            this.institutionsGroupSelect.value = this.institutionSelected;
            this.institutionsGroupSelectResetButton.removeAttribute('disabled');
        }
    }

    setAllOccupationOption() {
        this.occupationsGroupSelect.value = '';
        this.occupationsGroupSelect.innerHTML = null;
        // this.occupationSelected = null;
        this.occupationsGroupSelectResetButton.setAttribute('disabled', 'true');
        if (this.countriesModule.countriesSelected.size === 0 || this.countriesModule.countriesSelected.size === 0) {
            const defaultOption = document.createElement('OPTION');
            defaultOption.innerHTML = 'Bitte wählen Sie zunächst ein Land';
            defaultOption.value = '';
            this.occupationsGroupSelect.appendChild(defaultOption);
            this.occupationsGroupSelect.setAttribute('disabled', 'true');
        } else {
            const defaultOption = document.createElement('OPTION');
            defaultOption.innerHTML = 'Alle Berufe';
            defaultOption.value = '';
            this.occupationsGroupSelect.appendChild(defaultOption);
            this.occupationsGroupSelect.value = '';
            this.occupationsGroupSelect.removeAttribute('disabled');
        }
    }

    setOccupations(occupations) {
        this.setAllOccupationOption();
        if (occupations.length > 0) {
            occupations.forEach((occupation) => {
                const newOption = document.createElement('OPTION');
                newOption.innerHTML = occupation.nameGerman;
                newOption.value = occupation._id;
                this.occupationsGroupSelect.appendChild(newOption);
            });
        }
        if (!!this.occupationSelected) {
            this.occupationsGroupSelect.value = this.occupationSelected;
            this.occupationsGroupSelectResetButton.removeAttribute('disabled');
        }
    }

	loadOptions () {
		if (!this.countriesModule.countriesSelected.size || this.countriesModule.countriesSelected.size === 0) {
			return;
		}
	}
}
