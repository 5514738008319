export default class UrlParameterParser {

    static getParsedParameters(parametersList) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const parsedParameters = parametersList.map((parameter) => {
            const parsedParameter = urlParams.get(parameter);
            return parsedParameter ? { parameter: parameter, value: urlParams.get(parameter) } : undefined;
        });

        return parsedParameters.filter((parsedParameter) => parsedParameter)
    }

    static getParsedParameterValue(parameter) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const parsedParameter = urlParams.get(parameter);
        return parsedParameter ? { parameter: parameter, value: urlParams.get(parameter) } : undefined;
    }

    static deleteAllUrlParameter() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        urlParams.forEach(function(value, key) {
            urlParams.delete(key);
        });
        this._resetUrl();
    }

    static deleteUrlParameter(parameter) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        urlParams.delete(parameter);
        this._resetUrl();
    }

    static _resetUrl() {
        const currURL = window.location.href;
        const url = (currURL.split(window.location.host)[1]).split("?")[0];
        window.history.replaceState({}, document.title, url);
    }
}
