import HtmlHelper from '../utils/html-helper';

export default class PaginatorDom {

    constructor(dataTable) {
        this.dataTable = dataTable;

        this.pagination = document.querySelectorAll('.pagination-container');
        // this.tablePagination = document.querySelector('#tablePagination');

    }

    renderPagination() {
        this.pagination.forEach((paginationContainer) => {
            const tablePagination = paginationContainer.querySelector('ol.pagination')
            tablePagination.innerHTML = null;
            const pageCount = Math.ceil(this.dataTable.totalResults / this.dataTable.limit);
            if (pageCount > 1) {
                const previousPage = HtmlHelper.createElementFromHTML(`
        <li class="p-pagination__item p-pagination__item--previous">
            <button class="p-pagination__link--previous ${this.dataTable.page === 1 ? 'is-disabled' : ''}" ${this.dataTable.page === 1 ? 'disabled' : ''} role="button" title="Vorherige Seite"><i class="p-icon--contextual-menu">Previous page</i></button>
        </li>
    `);
                if (!previousPage.querySelector('button').classList.contains('is-disabled')) {
                    previousPage.querySelector('button').addEventListener('click', () => {
                        this.switchPage(event, this.dataTable.page - 1).then(() => this.renderPagination());
                    });
                }

                const firstPage = HtmlHelper.createElementFromHTML(`
        <li class="p-pagination__item p-pagination__item--first">
            <button class="p-pagination__link ${this.dataTable.page === 1 ? 'is-disabled' : ''}" role="button" ${this.dataTable.page === 1 ? 'disabled' : ''}">1</button>
        </li>
    `);
                if (!firstPage.querySelector('button').classList.contains('is-disabled')) {
                    firstPage.querySelector('button').addEventListener('click', () => {
                        this.switchPage(event, 1).then(() => this.renderPagination());
                    });
                }

                const lastPage = HtmlHelper.createElementFromHTML(`
        <li class="p-pagination__item p-pagination__item--last">
            <button role="button" class="p-pagination__link ${this.dataTable.page === pageCount ? 'is-disabled' : ''}" ${this.dataTable.page === pageCount ? 'disabled' : ''}">${Math.ceil(this.dataTable.totalResults / this.dataTable.limit)}</button>
        </li>
    `);
                if (!lastPage.querySelector('button').classList.contains('is-disabled')) {
                    lastPage.querySelector('button').addEventListener('click', () => {
                        this.switchPage(event, Math.ceil(this.dataTable.totalResults / this.dataTable.limit)).then(() => this.renderPagination());
                    });
                }

                const nextPage = HtmlHelper.createElementFromHTML(`
        <li class="p-pagination__item p-pagination__item--next">
            <button role="button" class="p-pagination__link--next ${this.dataTable.page === pageCount ? 'is-disabled' : ''}" ${this.dataTable.page === pageCount ? 'disabled' : ''} title="Nächste Seite"><i class="p-icon--contextual-menu">Next page</i></button>
        </li>
    `);
                if (!nextPage.querySelector('button').classList.contains('is-disabled')) {
                    nextPage.querySelector('button').addEventListener('click', () => {
                        this.switchPage(event, this.dataTable.page + 1).then(() => this.renderPagination());
                    });
                }

                tablePagination.appendChild(previousPage);
                tablePagination.appendChild(firstPage);

                const getPageLinkHtml = (pageNumber) => {

                    const pageLink = HtmlHelper.createElementFromHTML(`
           <li class="p-pagination__item p-pagination__item--page ${this.dataTable.page === pageNumber ? 'p-pagination__item--current' : ''}">
               <button role="button" class="p-pagination__link ${this.dataTable.page === pageNumber ? 'is-disabled' : ''}" ${this.dataTable.page === pageNumber ? 'disabled' : ''}">${pageNumber}</button>
           </li>
        `);
                    if (!pageLink.querySelector('button').classList.contains('is-disabled')) {
                        pageLink.querySelector('button').addEventListener('click', () => {
                            this.switchPage(event, pageNumber).then(() => this.renderPagination());
                        });
                    }

                    return pageLink;
                };

                //wenn  n <= 5 dann [alle]
                // sonst
                // wenn page <= 3; dann [1,2,3,4,5]
                // wenn page >= n-2; [n-4, n-3, n-2, n-1, n]
                // sonst [page-2,page-1, page, page+1, page+2]

                if (pageCount <= 5) {
                    for (let i = 2; i < pageCount; i++) {
                        const pageLinkHtml = getPageLinkHtml(i);
                        tablePagination.appendChild(pageLinkHtml);
                    }
                } else {
                    if (this.dataTable.page <= 3) {

                        for (let i = 2; i <= 5; i++) {
                            const pageLinkHtml = getPageLinkHtml(i);
                            tablePagination.appendChild(pageLinkHtml);
                        }
                        tablePagination.appendChild(HtmlHelper.createElementFromHTML('  <li class="p-pagination__item p-pagination__item--truncation">…</li>'));

                    } else if (this.dataTable.page >= pageCount - 2) {

                        tablePagination.appendChild(HtmlHelper.createElementFromHTML('  <li class="p-pagination__item p-pagination__item--truncation">…</li>'));
                        for (let i = pageCount - 4; i < pageCount; i++) {
                            const pageLinkHtml = getPageLinkHtml(i);
                            tablePagination.appendChild(pageLinkHtml);
                        }

                    } else {

                        tablePagination.appendChild(HtmlHelper.createElementFromHTML('  <li class="p-pagination__item p-pagination__item--truncation">…</li>'));

                        for (let i = this.dataTable.page - 2; i <= this.dataTable.page + 2; i++) {
                            const pageLinkHtml = getPageLinkHtml(i);
                            tablePagination.appendChild(pageLinkHtml);
                        }

                        tablePagination.appendChild(HtmlHelper.createElementFromHTML('  <li class="p-pagination__item p-pagination__item--truncation">…</li>'));

                    }
                }

                tablePagination.appendChild(lastPage);
                tablePagination.appendChild(nextPage);

            }
        })

        this.renderLimit();
        this.renderTotalResults();
    }

    renderTotalResults() {
        this.pagination.forEach((paginationContainer) => {
        const totalResultsParagraph = paginationContainer.querySelector('.total-results');
        const bottomPaginationContainer = document.getElementById('bottom-pagination-container');
        if (totalResultsParagraph) {
            totalResultsParagraph.remove();
        }
        let col = '';
        if (this.dataTable.totalResults !== '0') {
            if(bottomPaginationContainer) {
                bottomPaginationContainer.classList.remove('d-none');
            }
            col = 'col-2';
        } else {
            if(bottomPaginationContainer) {
                bottomPaginationContainer.classList.add('d-none');
            }
            col = 'col-4';
        }
            paginationContainer.appendChild(HtmlHelper.createElementFromHTML(`
            <div class="${col} total-results">
                <p>Ergebnisse ${new Intl.NumberFormat(navigator.language || navigator.userLanguage).format(this.dataTable.totalResults)}</p>
            </div>`));

        })
    }

    renderLimit() {
        this.pagination.forEach((paginationContainer) => {

        let limitSelect = paginationContainer.querySelector('.limit-select');
        if (limitSelect) {
            paginationContainer.querySelector('.paginator-information').remove();
        }
        if (this.dataTable.totalResults !== '0') {
            const limitSelectTemplate = HtmlHelper.createElementFromHTML(`
            <div class="paginator-information col-2">
                <select class="limit-select">
                </select>
            </div>    
        `);
            paginationContainer.appendChild(limitSelectTemplate);
            limitSelect = paginationContainer.querySelector('.limit-select');
            this.dataTable.limits.forEach(entry => {

                const newOption = document.createElement('OPTION');
                newOption.innerHTML = entry + ' pro Seite';
                newOption.value = entry;
                if (entry == this.dataTable.limit) {
                    newOption.setAttribute('selected', 'true');
                    newOption.value = this.dataTable.limit;
                }
                limitSelect.appendChild(newOption);
            });
            limitSelect.addEventListener('change', () => {
                this.dataTable.limit = limitSelect.value;
                this.dataTable.page = 1;
                this.dataTable.offset = 0;
                this.dataTable.dataModule.getData().then();
            });
        }
        })

    }

    async switchPage(event, pageNumber) {
        event.preventDefault();
        this.dataTable.page = pageNumber;
        this.dataTable.offset = Math.max(0, this.dataTable.page - 1) * this.dataTable.limit;
        this.dataTable.dataModule.getData().then();
    }
}
