
import HttpHelper from '../utils/http-helper';

export default class FaqgroupModule {

	constructor() {
		this.categories = [];
		this.categorySelected = null;
		this.categorySelect = document.querySelector('#categorySelect');
		this.groupes = [];
		this.groupSelected = null;
		this.groupSelect = document.querySelector('#groupSelect');
        this.groupSelectResetButton = document.querySelector('#groupSelectResetButton')
        this.categorySelectResetButton = document.querySelector('#categorySelectResetButton')

        this.groupChanged = true;
	}

	addEventListener(callerModule) {
		this.groupSelect.addEventListener('change', () => {
			this.groupSelected = this.groupSelect.value;
			this.groupChanged = true;
            this.groupSelectResetButton.removeAttribute('disabled')
			this.getCategories().then(() => {
				this.categorySelected = '';
				this.setAllCategoriesOptions();
				this.groupChanged = false;
				this.categorySelect.dispatchEvent(new Event('change'));
			});

		});
		this.categorySelect.addEventListener('change', () => {
			this.categorySelected = this.categorySelect.value;
            this.categorySelectResetButton.removeAttribute('disabled')

            if (callerModule.dataTable) callerModule.dataTable.page = 1;
            if (callerModule.dataTable) callerModule.dataTable.offset = 0;
            callerModule.getData().then();
		});
	}

    async getData () {
        Spinner.show(this);

        const searchInput = document.getElementById('tableSearchInput');

        if (this.countriesModule.countriesSelected.size === 0 && searchInput.value === "") {
            Spinner.hide(this);
            document.querySelector('#missingCountry').classList.remove('d-none')
            document.querySelector('#emptyDatasetRow').classList.add('d-none')
            document.querySelector('#dataRow').classList.add('d-none')
            document.querySelector('#info-text')?.classList.add('d-none')
            if (document.querySelector('#sub-headline')) {
                document.querySelector('#sub-headline').innerHTML = '';
            }

            return;
        } else {
            document.querySelector('#missingCountry')?.classList.add('d-none')
        }
        const staticFilter = { type : this.apiFilter };

        let filterQuery = {
            $and: []
        };
        if (this.countriesModule.countriesSelected.size === 0) {
            filterQuery = staticFilter;
        } else {
            filterQuery['$and'] = [ staticFilter, { $or: Array.from(this.countriesModule.countriesSelected).map((countryId) => ({ 'country': { $oid: countryId } })) } ];
        }

        const response = await HttpHelper.fetchData(`${location.origin}/api/public/bv-chapter?sort={"${this.dataTable.sort.field}":${this.dataTable.sort.direction}}&filter=${JSON.stringify(filterQuery)}&limit=${this.dataTable.limit}&offset=${this.dataTable.offset}&search=${this.dataTable.searchText}&reference=true`);
        document.querySelector('#emptyDatasetRow').classList.add('d-none')

        document.querySelector('#dataRow').classList.add('d-none')
        document.querySelector('#missingCountry').classList.add('d-none')

        this.totalResults = response?.headers['total-results'] ?? '0';
        this.dataTable.totalResults = this.totalResults;
        if (this.totalResults === '0') {
            NotificationsDom.renderInformationNotification('Für diese Auswahl wurden keine Datensätze gefunden!');
            document.querySelector('#info-text')?.classList.remove('d-none');
            this.dataTable.emptyDatasetRow.classList.remove('d-none');
            if(this.dataTable.tableSearchInput.value === '') {
                this.dataTable.dataRow.classList.add('d-none');
            }
        } else {
            if (this.subHeadlineTitle && document.querySelector('#sub-headline')) {
                document.querySelector('#sub-headline').innerHTML = `${this.subHeadlineTitle} in ${this.countriesModule.lastCountryNameSelected}`;
            }
            document.querySelector('#info-text')?.classList.remove('d-none');
            this.dataTable.emptyDatasetRow.classList.add('d-none');
            this.dataTable.dataRow.classList.remove('d-none');
        }
        const responseData = response?.data;
        const renderData = [ { context: 'bv-chapter' } ];
        await this.dataTable.renderTableData(responseData, this.dataTable.tableSearchInput, renderData);
        this.dataTable.paginator.renderPagination(this.dataTable);

        Spinner.hide(this);
    }

	async getAllData() {
		await this.getGroups().then(() => {
			this.setAllGroupsOptions();
		});
		await this.getCategories().then(() => {
			this.setAllCategoriesOptions();
		});
	}

	async getGroups() {
		const response = await HttpHelper.fetchData(`${location.origin}/api/public/faqgroup?sort={"nameShort":1}`);
		this.groupes = response?.data;
	}

	async getCategories() {
		let filterQuery = {
			$and: []
		};
		if (this.groupSelected) {
			filterQuery['$and'].push({ 'group': { $oid: this.groupSelected } });
		} else {
			filterQuery = {};
		}
		const response = await HttpHelper.fetchData(`${location.origin}/api/public/faqcategory?sort={"nameShort":1}&filter=${JSON.stringify(filterQuery)}`);
		this.categories = response?.data;
	}

	setAllGroupsOptions() {
		if (this.groupes.length > 0 && this.groupSelected == null) {
			this.groupSelect.value = '';
			this.groupSelect.innerHTML = null;
			this.groupSelected = null;

			const defaultOption = document.createElement('OPTION');
			defaultOption.innerHTML = 'Alle Themenbereiche';
			defaultOption.value = '';
			this.groupSelect.appendChild(defaultOption);

			this.groupes.forEach((group) => {
				const newOption = document.createElement('OPTION');
				newOption.innerHTML = group.nameShort;
				newOption.value = group._id;
				this.groupSelect.appendChild(newOption);
			});

			this.groupSelect.value = '';
			this.groupSelected = this.groupSelect.value;

			this.groupSelect.removeAttribute('disabled');
		}
	}

	setAllCategoriesOptions() {
		if (this.categories.length > 0  && !!this.groupChanged  && (this.categorySelected === null || this.categorySelected === '')) {
			this.categorySelect.value = '';
			this.categorySelect.innerHTML = null;
			this.categorySelected = null;

			const defaultOption = document.createElement('OPTION');
			defaultOption.innerHTML = 'Alle Themen';
			defaultOption.value = '';
			this.categorySelect.appendChild(defaultOption);

			this.categories.forEach((category) => {
				const newOption = document.createElement('OPTION');
				newOption.innerHTML = category.nameShort;
				newOption.value = category._id;
				this.categorySelect.appendChild(newOption);
			});

			this.categorySelect.value = '';
			this.categorySelected = this.categorySelect.value;

			this.categorySelect.removeAttribute('disabled');
		}
	}

}
