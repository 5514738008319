
import DataTableDom from '../dom/data-table-dom';
import NotificationsDom from '../dom/notifications-dom';
import HttpHelper from '../utils/http-helper';
import Spinner from '../dom/spinner';

export default class InstitutionsModule {

	constructor(countriesModule, locationModule, institutionTypeModule) {
		this.countriesModule = countriesModule;
		this.locationModule = locationModule;
		this.institutionTypeModule = institutionTypeModule;
		this.dataTable = null;
		this.totalResults = '';
	}

	initialise() {
		this.dataTable = new DataTableDom(this);
	}

	async getData () {
        Spinner.show(this);

        const searchInput = document.getElementById('tableSearchInput');

        if (this.countriesModule.countriesSelected.size === 0 && searchInput.value === "") {
            Spinner.hide(this);
            document.querySelector('#missingCountry').classList.remove('d-none')
            document.querySelector('#emptyDatasetRow').classList.add('d-none')
            document.querySelector('#info-text')?.classList.add('d-none')
            document.querySelector('#dataRow').classList.add('d-none')
            if (document.querySelector('#sub-headline')) document.querySelector('#sub-headline').innerHTML = '';
            return;
        }

        let filterQuery = {
            $and: []
        };
        if (this.countriesModule.countriesSelected.size === 0) {
            filterQuery = {};
        } else {
            filterQuery['$and'] = [{$or: Array.from(this.countriesModule.countriesSelected).map((countryId) => ({'country': {$oid: countryId}}))}];

            if (this.locationModule.locationSelected) {
                filterQuery['$and'].push({'location': {$oid: this.locationModule.locationSelected}});
            }
            if (this.institutionTypeModule.institutionTypeSelected) {
                filterQuery['$and'].push({'institutionType': {$oid: this.institutionTypeModule.institutionTypeSelected}});
            }
        }
        const response = await HttpHelper.fetchData(`${location.origin}/api/public/institution?sort={"${this.dataTable.sort.field}":${this.dataTable.sort.direction}}&filter=${JSON.stringify(filterQuery)}&limit=${this.dataTable.limit}&offset=${this.dataTable.offset}&search=${this.dataTable.searchText}&reference=true`);
        document.querySelector('#emptyDatasetRow').classList.add('d-none')

        document.querySelector('#dataRow').classList.add('d-none')
        document.querySelector('#missingCountry').classList.add('d-none')

        this.totalResults = response?.headers['total-results'] ?? '0';
        this.dataTable.totalResults = this.totalResults;
        if (this.totalResults === '0') {
            NotificationsDom.renderInformationNotification('Für diese Auswahl wurden keine Datensätze gefunden!');
            document.querySelector('#info-text')?.classList.remove('d-none');
            this.dataTable.emptyDatasetRow.classList.remove('d-none');
            if (this.dataTable.tableSearchInput.value === '') {
                this.dataTable.dataRow.classList.add('d-none');
            }
        } else {
            document.querySelector('#info-text')?.classList.remove('d-none');
            this.dataTable.emptyDatasetRow.classList.add('d-none');
            this.dataTable.dataRow.classList.remove('d-none');
        }
        const responseData = response?.data;
        const renderData = [{context: 'institution'}];
        this.dataTable.renderTableData(responseData, this.dataTable.tableSearchInput, renderData);
        this.dataTable.paginator.renderPagination(this.dataTable);

        Spinner.hide(this);
	}

	static async getId(id) {
		const response = await HttpHelper.fetchData(`${location.origin}/api/public/institution/${id}?reference=true`);
		return response?.data;
	}

	loadOptions () {
		if (!this.countriesModule.countriesSelected.size || this.countriesModule.countriesSelected.size === 0) {
			this.locationModule.select.setAttribute('disabled', 'true');
			this.locationModule.resetButton.setAttribute('disabled', 'true');
			this.institutionTypeModule.select.setAttribute('disabled', 'true');
			this.institutionTypeModule.resetButton.setAttribute('disabled', 'true');
			this.locationModule.setAllLocationsOption();
			this.institutionTypeModule.setAllInstitutionTypeOption();
			return;
		}

		this.locationModule.loadLocations();
		this.institutionTypeModule.loadInstitutionTypes();
	}
}
