import DataTableDom from '../dom/data-table-dom';
import NotificationsDom from '../dom/notifications-dom';
import HttpHelper from '../utils/http-helper';
import Spinner from '../dom/spinner';

export default class ExpertisesModule {

    constructor(countriesModule, type) {
        this.countriesModule = countriesModule;

        this.sortOverride = {
            field: 'nameShort',
            direction: 1
        };

        // this.nativeDatePicker = new NativeDatePicker(this);
        this.dataTable = null;
        this.totalResults = '';
        this.checkboxGfg = document.querySelector('#checkboxGfg');
        if (type?.includes('gfg')) {
            this.type = 'expertise-gfg';
            this.sortOverride = {
                field: 'desiredQualification',
                direction: 1
            }
        } else {
            this.type = 'expertise';
        }
        this.dateFrom = document.querySelector('#dateFrom')
        this.dateFrom.addEventListener('change', () => {
            this.dateFromInputResetButton.removeAttribute('disabled');
            this.getData()
        })
        this.dateTo = document.querySelector('#dateTo')
        this.dateTo.addEventListener('change', () => {
            this.dateToInputResetButton.removeAttribute('disabled');
            this.getData()
        })

        this.dateFromInputResetButton = document.querySelector('#dateFromInputResetButton');
        this.dateFromInputResetButton.addEventListener('click', () => {
            this.dateFrom.value = null;
            this.dateFromInputResetButton.setAttribute('disabled', 'true');
            this.getData()

        });
        this.dateToInputResetButton = document.querySelector('#dateToInputResetButton');
        this.dateToInputResetButton.addEventListener('click', () => {
            this.dateTo.value = null;
            this.dateToInputResetButton.setAttribute('disabled', 'true');
            this.getData()

        });
    }

    initialise() {
        this.dataTable = new DataTableDom(this, this.sortOverride);
    }


    async getData() {
        Spinner.show(this);

        const searchInput = document.getElementById('tableSearchInput');

        if (this.countriesModule.countriesSelected.size === 0 && searchInput.value === "") {
            Spinner.hide(this);
            document.querySelector('#missingCountry').classList.remove('d-none')
            document.querySelector('#emptyDatasetRow').classList.add('d-none')
            document.querySelector('#info-text')?.classList.add('d-none')
            document.querySelector('#dataRow').classList.add('d-none')
            if (document.querySelector('#sub-headline')) document.querySelector('#sub-headline').innerHTML = '';
            return;
        }

        let filterQuery = {
            $and: [ { gfg: this.type === 'expertise-gfg' } ]
        };
        if (this.countriesModule.countriesSelected.size === 0) {
            filterQuery['$and'].push( {} );
        } else {
            filterQuery['$and'].push( { $or: Array.from(this.countriesModule.countriesSelected).map((countryId) => ({ 'country': { $oid: countryId } })) } );
            // filterQuery['$and'] = [ this.nativeDatePicker.dateFilter ,  ];
        }
        const lower = this.dateFrom.value
        const upper = this.dateTo.value
        if (lower) {
            const field = this.type === 'expertise-gfg' ? 'finishYear' : 'date'
            const value = this.type === 'expertise-gfg' ? +lower : { $dateFromString: new Date(`${lower}-01-01`) }
            filterQuery['$and'].push(
                { [field]: { $gte: value } }
            )
        }
        if (upper) {
            const field = this.type === 'expertise-gfg' ? 'finishYear' : 'date'
            const value = this.type === 'expertise-gfg' ? +upper : { $dateFromString: new Date(`${upper}-12-31`) }
            filterQuery['$and'].push(
                { [field]: { $lte: value } }
            )
        }

        const response = await HttpHelper.fetchData(`${location.origin}/api/public/${this.type}?sort={"${this.dataTable.sort.field}":${this.dataTable.sort.direction}}&filter=${JSON.stringify(filterQuery)}&limit=${this.dataTable.limit}&offset=${this.dataTable.offset}&search=${this.dataTable.searchText}&reference=true`);
        document.querySelector('#emptyDatasetRow').classList.add('d-none')

        document.querySelector('#dataRow').classList.add('d-none')
        document.querySelector('#missingCountry').classList.add('d-none')

        this.totalResults = response?.headers['total-results'] ?? '0';
        this.dataTable.totalResults = this.totalResults;
        if (this.totalResults === '0') {
            NotificationsDom.renderInformationNotification('Für diese Auswahl wurden keine Datensätze gefunden!');
            document.querySelector('#info-text')?.classList.remove('d-none');
            this.dataTable.emptyDatasetRow.classList.remove('d-none');
            if (this.dataTable.tableSearchInput.value === '') {
                this.dataTable.dataRow.classList.add('d-none');
            }
        } else {
            document.querySelector('#info-text')?.classList.remove('d-none');
            this.dataTable.emptyDatasetRow.classList.add('d-none');
            this.dataTable.dataRow.classList.remove('d-none');
        }
        const responseData = response?.data;
        const renderData = [ { context: this.type } ];
        this.dataTable.renderTableData(responseData, this.dataTable.tableSearchInput, renderData);
        this.dataTable.paginator.renderPagination(this.dataTable);

        Spinner.hide(this);
    }

    static async getId(id) {
        const response = await HttpHelper.fetchData(`${location.origin}/api/public/expertise/${id}?reference=true`);
        return response?.data;
    }

    static async getGfgId(id) {
        const response = await HttpHelper.fetchData(`${location.origin}/api/public/expertise-gfg/${id}?reference=true`);
        return response?.data;
    }

    loadOptions() {
        if (!this.countriesModule.countriesSelected.size || this.countriesModule.countriesSelected.size === 0) {
            return;
        }
    }
}
