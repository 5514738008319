import HtmlHelper from '../utils/html-helper';
import PaginatorDom from './paginator-dom';
import ModalDom from './modal-dom';
import { arraySortAlphabetically, DatatypeHelper } from '../utils/datatype-helper';
import DocumentsModule from "../modules/documents-module";
import ReferenceModule from "../modules/reference-module";
import { toPath } from 'lodash'
import UrlParameterParser from "../utils/url-parameter-parser";

export default class DataTableDom {

    constructor(dataModule, defaultSort) {
        this.dataModule = dataModule;
        this.modalDom = new ModalDom();
        this.headers = document.querySelectorAll('th[aria-sort]');
        this.tableBody = document.querySelector('#tableBody');
        this.tableSearchInput = document.querySelector('#tableSearchInput');
        this.searchForm = document.querySelector('#searchForm');
        this.tableSearchInputResetButton = document.querySelector('#tableSearchInputResetButton');
        this.tableResetAllButton = document.querySelector('#tableResetAllButton');
        this.resetAllButton = null;
        this.emptyDatasetRow = document.querySelector('#emptyDatasetRow');
        this.dataRow = document.querySelector('#dataRow');
        this.dataTable = document.querySelector('#dataTable');

        this.lastLabelContent = '';

        this.paginator = new PaginatorDom(this);

        if (defaultSort) {
            this.sort = {
                field: defaultSort.field,
                direction: defaultSort.direction
            };
        } else {
            this.sort = {
                field: 'nameShort',
                direction: 1
            };
        }

        this.offset = 0;
        this.page = 1;
        this.limits = [ 10, 25, 50, 100 ];
        this.limit = 10;
        this.totalResults = null;
        this.searchText = '';
        this.timeout = null;

        this.initialise();
    }

    initialise() {
        this.tableSearchInputResetButton.setAttribute('disabled', 'true');
        this.tableSearchInput.value = '';
        this.searchText = '';
        this.addResetAllButton();
        this.addEventListeners(this);
    }

    addResetAllButton() {
        const buttonTemplate = HtmlHelper.createElementFromHTML(`
            <button type="reset" class="p-button--brand" id="resetAllButton">
                <i class="p-icon--close is-light"></i>
                Alles zurücksetzen
            </button>
	    `);
        try {
            this.tableResetAllButton.appendChild(buttonTemplate);
        } catch (e) {
            
        }
        this.resetAllButton = document.querySelector('#resetAllButton');
    }

    addEventListeners(context) {
        context.headers.forEach((header) => {
            header.classList.add('clickable');
            header.addEventListener('click', function () {
                context.sort = HtmlHelper.changeSort(context.sort, header);
                context.page = 1;
                context.offset = 0;
                context.paginator.renderPagination(context);
                context.dataModule.getData()
                    .then();
            });
        });
        this.searchForm?.addEventListener('submit', event => {
            event.preventDefault();
            clearTimeout(context.timeout);
            // Make a new timeout set to go off in 1000ms (1 second)
            context.timeout = setTimeout(function () {
                context.page = 1;
                context.offset = 0;
                context.searchText = context.tableSearchInput.value;
                context.dataModule.getData()
                    .then();
            }, 0);
        })
        this.tableSearchInput.addEventListener('input', () => {
            if (context.tableSearchInput.value === '') {
                context.tableSearchInputResetButton.setAttribute('disabled', 'true');
            } else {
                context.tableSearchInputResetButton.removeAttribute('disabled');
            }
        });
        this.tableSearchInput.addEventListener('keydown', event => {
            if (context.tableSearchInput.value === '') {
                context.tableSearchInputResetButton.setAttribute('disabled', 'true');
            } else {
                context.tableSearchInputResetButton.removeAttribute('disabled');
            }
            if (event.code === "Enter" || event.code === "NumpadEnter") {  //checks whether the pressed key is "Enter"
                event.preventDefault();
                // Clear the timeout if it has already been set.
                // This will prevent the previous task from executing
                // if it has been less than <MILLISECONDS>
                clearTimeout(context.timeout);
                // Make a new timeout set to go off in 1000ms (1 second)
                context.timeout = setTimeout(function () {
                    context.page = 1;
                    context.offset = 0;
                    context.searchText = context.tableSearchInput.value;
                    context.dataModule.getData()
                        .then();
                }, 0);
            }
        });
        context.tableSearchInputResetButton.addEventListener('click', () => {
            context.resetDataTable();
        });
        context.resetAllButton?.addEventListener('click', () => {
            const dateResetButtons = document.querySelectorAll("#dateFromInputResetButton, #dateToInputResetButton");
            const dateButtons = document.querySelectorAll("#dateFrom, #dateTo");

            UrlParameterParser.deleteAllUrlParameter();

            if (!!dateButtons) {
                dateButtons.forEach(button => {
                    button.value = null;
                });
            }
            if (!!dateResetButtons) {
                dateResetButtons.forEach(resetButton => {
                    resetButton?.setAttribute('disabled', 'true');
                });
            }
            if (context.dataModule?.countriesModule?.searchCountriesInputResetButton) {
                context.dataModule?.countriesModule?.searchCountriesInputResetButton?.dispatchEvent(new Event('click'))
            } else {
                const resetButtons = document.querySelectorAll("[id$='ResetButton']")
                this.resetDataTable();
                resetButtons.forEach((resetButton, index) => {
                    resetButton?.dispatchEvent(new Event('click'));
                });
            }
        });
    }

    getTableEntries(context, dataArray, tableSearchInput) {
        let tableArray = '';
        for (let i = 1; i < dataArray.length; i++) {
            let textContent = ' ';
            const splited = dataArray[i].data.split('.');
            // let parsed = ' ';
            // if(splited.length === 1) {
            // 	// const parameter = splited[0];
            // 	// if(context[parameter]) {
            //     //     if(context[parameter] instanceof String) {
            //     //         parsed = context[parameter]//.replace(/(<([^>]+)>)/gi, "");
            //     //     } else {
            //     //         parsed = context[parameter];
            //     //     }
            //     // }
            // 	parsed = context[splited[0]]
            // } else if(splited.length === 2) {
            // 	const parameter1 = splited[0];
            // 	const parameter2 = splited[1];
            //
            //     if (context[parameter1]) {
            //         if (Array.isArray(context[parameter1])) {
            //             context[parameter1]?.forEach((entry) => {
            //                 if (!!entry) {
            //                     if (!!entry[parameter2]) {
            //                         parsed += `<span class="d-block table--cell--list--item"> ${entry[parameter2]}</span>`//?.replace(/(<([^>]+)>)/gi, "");
            //                     }
            //                 }
            //             });
            //         } else {
            //             parsed = context[parameter1][parameter2]//?.replace(/(<([^>]+)>)/gi, "");
            //         }
            //     }
            //
            // } else if (splited.length === 3) {
            //     const parameter1 = splited[0];
            //     const parameter2 = splited[1];
            //     const parameter3 = splited[2];
            //
            //     if (context[parameter1]) {
            //         if (context[parameter1][parameter2]) {
            //             if (context[parameter1][parameter2][parameter3]) {
            //                 parsed = context[parameter1][parameter2][parameter3]//?.replace(/(<([^>]+)>)/gi, "");
            //             }
            //         } else if (Array.isArray(context[parameter1])) {
            //             context[parameter1]?.forEach((entry, index) => {
            //                 if (!!entry) {
            //                     if (!!entry[parameter2]) {
            //                         if (!!entry[parameter2][parameter3]) {
            //                             parsed += `<span class="d-block table--cell--list--item"> ${entry[parameter2][parameter3]}</span>`
            //                             // parsed += entry[parameter2][parameter3]//?.replace(/(<([^>]+)>)/gi, "");
            //                             // if (index !== (context[parameter1].length - 1)) {
            //                             //     parsed += ',<br>';
            //                             // }
            //                         }
            //                     }
            //                 }
            //             });
            //         }
            //     }
            // }
            const pathSegments = toPath(dataArray[i].data)

            const viewValues = new Set();
            function getValue(segments, _context) {
                if (!_context) return _context;

                if (segments.length === 1) {
                    if (Array.isArray(_context[segments[0]])) {
                        return _context[segments[0]]?.reduce((htmlSpanString, elem) => {
                            // check if if in idSet
                            if (viewValues.has(elem) || !elem) return htmlSpanString;
                            htmlSpanString += `<span class="d-block table--cell--list--item">${elem}</span>`
                            viewValues.add(elem);
                            return htmlSpanString
                        }, '')
                    }
                    if (Array.isArray(_context)) {
                        return _context?.reduce((htmlSpanString, _elem) => {
                            // check if if in idSet
                            const elem = _elem[segments[0]];
                            if (viewValues.has(elem) || !elem) return htmlSpanString;
                            htmlSpanString += `<span class="d-block table--cell--list--item">${elem}</span>`
                            viewValues.add(elem);
                            return htmlSpanString
                        }, '')
                    }


                    const elem = _context[segments[0]]?.toString();
                    if (viewValues.has(elem) || !elem) return '';
                    viewValues.add(elem);

                    return `${elem}`;
                }
                // stack 1: office, relatedcompetences, [competence,nameShort]
                // stack 2: relatedcompetences[], competence, [nameShort]
                // stack 3: competence,

                const [ firstPath, ...rest ] = segments
                if (Array.isArray(_context)) {
                    return _context.reduce((htmlSpanString, obj) => {

                        const val = getValue(rest, obj[firstPath])
                        if (!val) return htmlSpanString;
                        htmlSpanString += `<span class="d-block table--cell--list--item">${val}</span>`
                        // htmlSpanString += getValue(rest, obj[firstPath]);
                        return htmlSpanString
                    }, '')
                }
                return getValue(rest, _context[firstPath])
            }

            let parsed = getValue(pathSegments, context, 0)

            if (!!Date.parse(parsed) && typeof parsed == 'string' && parsed.includes('T')) {
                let toDate = parsed.split('T')[0];
                if (!!Date.parse(toDate)) {
                    parsed = DatatypeHelper.formattedDate(toDate);
                }
            }
            textContent = parsed//HtmlHelper.domHighlightString(parsed, tableSearchInput);

            if (dataArray[i].label.toLowerCase().includes('Zeugnisgruppe'.toLowerCase())) {
                if (this.lastLabelContent.toLowerCase().includes(textContent.toLowerCase())) {
                    textContent = `<small class="childTableEntry">${textContent}</small>`;
                }
                this.lastLabelContent = textContent;
            }

            if (textContent?.includes("undefined") || textContent?.includes("null")) {
                textContent = textContent.replace(/undefined|null/g, ' ');
            }

            if (!!context['isChildCertificate'] && i === 1) {
                tableArray += `
                    <td class="childTableEntry" aria-label="${dataArray[i].label}">${textContent}</td>
                `;
            } else if (!!context['bvChapter'] && i === 1) {
                tableArray += `
                    <td class="childTableEntry depth--${context.depth ?? 0}" aria-label="${dataArray[i].label}">${textContent}</td>
                `;
            } else {
                tableArray += `
                <td aria-label="${dataArray[i].label}">${textContent ?? ''}</td>
            `;
            }
        }

        return tableArray;
    }

    async renderTableData(data, tableSearchInput, dataArray) {
        const tableHeader = document.querySelector('thead');

        this.tableBody.innerHTML = null;
        if (!data?.length) {
            tableHeader.classList.add('d-none');
        } else {
            tableHeader.classList.remove('d-none');
            tableHeader.querySelectorAll('th').forEach(header => {
                dataArray.push(
                    {
                        data: header.attributes.getNamedItem('data-fieldname').nodeValue,
                        label: header.textContent
                    }
                );
            });

            this.dataRow.classList.remove('d-none');

            let tr = '';
            if (dataArray.find(element => element.context === 'degrees')) {
                data.forEach((degree) => {
                    const { ...rawData } = degree;
                    DatatypeHelper.addUnit(degree.degreeType, 'studiesMinLength', 'Jahr(e)');
                    DatatypeHelper.addUnit(degree.degreeType, 'studiesMaxLength', 'Jahr(e)');
                    DatatypeHelper.addUnit(degree, 'theoryMin', 'Jahr(e)');
                    DatatypeHelper.addUnit(degree, 'theoryMax', 'Jahr(e)');
                    DatatypeHelper.addUnit(degree, 'practiceMin', 'Jahr(e)');
                    DatatypeHelper.addUnit(degree, 'practiceMax', 'Jahr(e)');

                    degree.nameShortTable = degree.nameGerman ? `${degree.nameShort}<br>- ${degree.nameGerman}` : degree.nameShort;
                    if (degree.degreeType) {
                        degree.degreeType.nameShortTable = degree.degreeType?.nameGerman ? `${degree.degreeType?.nameShort}<br>- ${degree.degreeType?.nameGerman}` : degree.degreeType?.nameShort;
                    }
                    if (degree.fieldOfStudy) {
                        degree.fieldOfStudy.nameShortTable = degree.fieldOfStudy?.nameGerman ? `${degree.fieldOfStudy?.nameShort}<br>- ${degree.fieldOfStudy?.nameGerman}` : degree.fieldOfStudy?.nameShort;
                    }

                    const row = `
                    <tr>
                        ${this.getTableEntries(degree, dataArray, tableSearchInput)}
                    </tr>
                 `;
                    tr = HtmlHelper.createElementFromHTML(row);
                    tr.classList.add('pointer');
                    tr.addEventListener('click', () => {
                        ModalDom.openDegreeModal(rawData).then();
                    });
                    this.tableBody.appendChild(tr);
                });
            }

            if (dataArray.find(element => element.context === 'institution')) {
                data.forEach((institution) => {

                    if (institution.alternativeNames?.length !== 0 ||
                        institution.aliasNames?.length !== 0 ||
                        institution.outdatedNames?.length !== 0 ||
                        !!institution.blacklisted) {

                        let tempNameShortTableArray = [];
                        let tempNameGermanTableArray = [];

                        if(institution.nameShort && institution.nameShort.length !== 0) {
                            tempNameShortTableArray.push(institution.nameShort);
                            tempNameGermanTableArray.push(institution.nameGerman);
                        }

                        [ 'alternativeNames', 'aliasNames', 'outdatedNames' ].forEach(key => {
                            institution[key]?.forEach((propertyName, idx) => {
                                if (propertyName?.nameShort && propertyName?.nameShort?.length !== 0 ) {
                                    tempNameShortTableArray.push(propertyName.nameShort);
                                    tempNameGermanTableArray.push(propertyName.nameGerman);
                                }
                            });
                        });

                        institution.nameShortTable = tempNameShortTableArray;
                        institution.nameGermanTable = tempNameGermanTableArray;

                        if(!!institution.blacklisted && institution.nameShortTable.length !== 0) {
                            institution.nameShortTable[0] = institution.nameShortTable[0] + '<i class="p-icon--warning"></i>';
                        }
                        if(institution.nameShortTable.length === 1) {
                            institution.nameShortTable = institution.nameShortTable[0];
                        }
                    } else {
                        institution.nameShortTable = institution.nameShort;
                    }

                    const row = `
                    <tr>
                        ${this.getTableEntries(institution, dataArray, tableSearchInput)}
                    </tr>
                 `;
                    tr = HtmlHelper.createElementFromHTML(row);
                    tr.classList.add('pointer');
                    tr.addEventListener('click', () => {
                        ModalDom.openInstitutionModal(institution).then();
                    });
                    this.tableBody.appendChild(tr);
                });
            }

            if (dataArray.find(element => element.context === 'occupation')) {
                data.forEach((occupation) => {

                    const row = `
                    <tr>
                        ${this.getTableEntries(occupation, dataArray, tableSearchInput)}
                    </tr>
                 `;
                    tr = HtmlHelper.createElementFromHTML(row);
                    tr.classList.add('pointer');
                    tr.addEventListener('click', () => {
                        ModalDom.openOccupationModal(occupation).then();
                    });
                    this.tableBody.appendChild(tr);
                });
            }

            if (dataArray.find(element => element.context === 'degree-type')) {
                data.forEach((degreeType) => {
                    const { ...rawData } = degreeType
                    DatatypeHelper.addUnit(degreeType, 'studiesMinLength', 'Jahr(e)');
                    DatatypeHelper.addUnit(degreeType, 'studiesMaxLength', 'Jahr(e)');

                    const row = `
                    <tr>
                        ${this.getTableEntries(degreeType, dataArray, tableSearchInput)}
                    </tr>
                 `;
                    tr = HtmlHelper.createElementFromHTML(row);
                    tr.classList.add('pointer');
                    tr.addEventListener('click', () => {
                        ModalDom.openDegreeTypeInformationModal(rawData).then();
                    });
                    this.tableBody.appendChild(tr);
                });
            }

            const certificates = dataArray.find(element => element.context === 'certificates');
            const certificatesMsa = dataArray.find(element => element.context === 'certificates-msa');
            if (certificates || certificatesMsa) {
                data.forEach((certificate) => {

                    const row = `
                    <tr>
                        ${this.getTableEntries(certificate, dataArray, tableSearchInput)}
                    </tr>
                 `;
                    tr = HtmlHelper.createElementFromHTML(row);
                    tr.classList.add('pointer');
                    tr.addEventListener('click', () => {
                        if (certificates) {
                            if (certificate.isChildCertificate) {
                                ModalDom.openCertificateChildInformationModal(certificate).then();
                            } else if (!certificate.children || certificate.children?.length === 0) {
                                ModalDom.openCertificateWithoutChildrenModal(certificate).then();
                            } else if (certificate.children?.length > 0) {
                                ModalDom.openCertificateWithChildrenModal(certificate).then();
                            }
                        } else if (certificatesMsa) {
                            ModalDom.openCertificateMsaModal(certificate).then();
                        }
                    });
                    this.tableBody.appendChild(tr);
                });
            }

            const expertiseNonGfg = dataArray.find(element => element.context === 'expertise');
            const expertiseGfg = dataArray.find(element => element.context === 'expertise-gfg');
            if (expertiseNonGfg || expertiseGfg) {
                for (const expertise of data) {

                    if (expertiseGfg) {
                        expertise.references = ReferenceModule.getReferenceObject(expertise.reference);

                        if (!expertise?.institution) {
                            expertise.institution = { nameShort: expertise.institutionNew };
                        }
                        expertise.fnu = 'nein';
                        expertise.curriculum = 'nein';
                        expertise.referencesFNUE = [];
                        expertise.referencesCurriculum = [];
                        if (Array.isArray(expertise.references?.in) && expertise.references.in.length > 0) {
                            for (const reference of expertise.references.in) {
                                if (!!reference.key && reference.key === 'Document') {
                                    if (!!reference.object._id) {
                                        const doc = await DocumentsModule.getId(reference.object._id);
                                        if (doc?.type?.nameShort === 'Fächer- und Notenübersicht (FNÜ)') {
                                            expertise.referencesFNUE.push(doc);
                                            expertise.fnu = 'ja';
                                        }
                                        if (doc?.type?.nameShort === 'Curriculum') {
                                            expertise.referencesCurriculum.push(doc);
                                            expertise.curriculum = 'ja';
                                        }
                                    }
                                }
                            }
                        }
                        if (Array.isArray(expertise.references?.out) && expertise.references.out.length > 0) {
                            for (const reference of expertise.references.out) {
                                if (!!reference.key && reference.key === 'Document') {
                                    if (!!reference.object._id) {
                                        const doc = await DocumentsModule.getId(reference.object._id);
                                        if (doc?.type?.nameShort === 'Fächer- und Notenübersicht (FNÜ)') {
                                            expertise.referencesFNUE.push(doc);
                                            expertise.fnu = 'ja';
                                        }
                                        if (doc?.type?.nameShort === 'Curriculum') {
                                            expertise.referencesCurriculum.push(doc);
                                            expertise.curriculum = 'ja';
                                        }
                                    }
                                }
                            }
                        }
                    }
                    const row = `
                    <tr>
                        ${this.getTableEntries(expertise, dataArray, tableSearchInput)};
                    </tr>
                 `;
                    tr = HtmlHelper.createElementFromHTML(row);
                    tr.classList.add('pointer');
                    tr.addEventListener('click', () => {
                        if (expertiseNonGfg) {
                            ModalDom.openExpertiseModal(expertise).then();
                        } else if (expertiseGfg) {
                            ModalDom.openExpertiseGfgModal(expertise).then();
                        }
                    });
                    this.tableBody.appendChild(tr);
                }
            }

            if (dataArray.find(element => element.context === 'bv-chapter')) {
                data.forEach((bvChapter) => {

                    const row = `
                    <tr>
                        ${this.getTableEntries(bvChapter, dataArray, tableSearchInput)}
                    </tr>
                 `;
                    tr = HtmlHelper.createElementFromHTML(row);
                    tr.classList.add('pointer');
                    tr.addEventListener('click', () => {
                        ModalDom.openBvChapterModal(bvChapter).then();
                    });
                    this.tableBody.appendChild(tr);
                });
            }

            if (dataArray.find(element => element.context === 'glossary')) {
                data.forEach((glossary) => {

                    const row = `
                    <tr>
                        ${this.getTableEntries(glossary, dataArray, tableSearchInput)}
                    </tr>
                 `;
                    tr = HtmlHelper.createElementFromHTML(row);
                    // tr.classList.add('excludePointer');
                    this.tableBody.appendChild(tr);
                });
            }

            if (dataArray.find(element => element.context === 'news')) {
                data.forEach((news) => {

                    const row = `
                    <tr>
                        ${this.getTableEntries(news, dataArray, tableSearchInput)}
                    </tr>
                 `;
                    tr = HtmlHelper.createElementFromHTML(row);
                    tr.classList.add('pointer');
                    tr.addEventListener('click', () => {
                        ModalDom.openNewsModal(news).then();
                    });
                    this.tableBody.appendChild(tr);
                });
            }

            if (dataArray.find(element => element.context === 'faq')) {
                data.forEach((faq) => {

                    const row = `
                    <tr>
                        ${this.getTableEntries(faq, dataArray, tableSearchInput)}
                    </tr>
                 `;
                    tr = HtmlHelper.createElementFromHTML(row);
                    tr.classList.add('pointer');
                    tr.addEventListener('click', () => {
                        ModalDom.openFaqModal(faq).then();
                    });
                    this.tableBody.appendChild(tr);
                });
            }

            if (dataArray.find(element => element.context === 'recognition-office')) {
                data.forEach((recognitionOffice) => {

                    const row = `
                    <tr>
                        ${this.getTableEntries(recognitionOffice, dataArray, tableSearchInput)}
                    </tr>
                 `;
                    tr = HtmlHelper.createElementFromHTML(row);
                    tr.classList.add('pointer');
                    tr.addEventListener('click', () => {
                        ModalDom.openRecognitionOfficeModal(recognitionOffice).then();
                    });
                    this.tableBody.appendChild(tr);
                });
            }

            if (dataArray.find(element => element.context === 'documents-curricula-gfg')) {
                data.forEach((documentsCurriculaGfg) => {
                    documentsCurriculaGfg.degreesTable = '';
                    documentsCurriculaGfg.occupationsTable = '';
                    documentsCurriculaGfg.institutionsTable = '';
                    documentsCurriculaGfg.validDateTable = '';
                    if (!!documentsCurriculaGfg.validFrom || !!documentsCurriculaGfg.validUntil
                        || !!documentsCurriculaGfg.validFromYear || !!documentsCurriculaGfg.validUntilYear) {
                        const from = documentsCurriculaGfg.validFromYear ? documentsCurriculaGfg.validFromYear : documentsCurriculaGfg.validFrom ? DatatypeHelper.getYearFromDate(documentsCurriculaGfg.validFrom) : '';
                        const until = documentsCurriculaGfg.validUntilYear ? documentsCurriculaGfg.validUntilYear : documentsCurriculaGfg.validUntil ? DatatypeHelper.getYearFromDate(documentsCurriculaGfg.validUntil) : '';

                        documentsCurriculaGfg.validFromUntilTable = from && until !== ''
                            ? `${from}-${until}`
                            : from ? `ab ${from}`
                                : until ? `bis ${until}`
                                    : '';
                    }
                    if (!!documentsCurriculaGfg.validDate || !!documentsCurriculaGfg.validDateYear) {
                        documentsCurriculaGfg.validDateTable = documentsCurriculaGfg.validDateYear ? documentsCurriculaGfg.validDateYear : documentsCurriculaGfg.validDate ? DatatypeHelper.getYearFromDate(documentsCurriculaGfg.validDate) : '';
                    }

                    if (documentsCurriculaGfg?.reference?.out?.Degree?.length > 0
                        || documentsCurriculaGfg?.reference?.in?.Degree?.length > 0) {
                        documentsCurriculaGfg?.reference?.out?.Degree?.forEach((degree, index) => {
                            if (!!degree.nameGerman) {
                                documentsCurriculaGfg.degreesTable += `- ${degree.nameGerman}`
                                if (index !== documentsCurriculaGfg?.reference?.out?.Degree?.length - 1) {
                                    documentsCurriculaGfg.degreesTable += '<br>';
                                }
                            }
                        });
                        documentsCurriculaGfg?.reference?.in?.Degree?.forEach((degree, index) => {
                            if (!!degree.nameGerman) {
                                documentsCurriculaGfg.degreesTable += `- ${degree.nameGerman}`
                                if (index !== documentsCurriculaGfg?.reference?.out?.Degree?.length - 1) {
                                    documentsCurriculaGfg.degreesTable += '<br>';
                                }
                            }
                        });
                    }
                    if (documentsCurriculaGfg?.reference?.out?.Occupation?.length > 0
                        || documentsCurriculaGfg?.reference?.in?.Occupation?.length > 0) {
                        documentsCurriculaGfg?.reference?.out?.Occupation?.forEach((occupation, index) => {
                            if (!!occupation.nameGerman) {
                                documentsCurriculaGfg.occupationsTable += `- ${occupation.nameGerman}`
                                if (index !== documentsCurriculaGfg?.reference?.out?.Occupation?.length - 1) {
                                    documentsCurriculaGfg.occupationsTable += '<br>';
                                }
                            }
                        });
                        documentsCurriculaGfg?.reference?.in?.Occupation?.forEach((occupation, index) => {
                            if (!!occupation.nameGerman) {
                                documentsCurriculaGfg.occupationsTable += `- ${occupation.nameGerman}`
                                if (index !== documentsCurriculaGfg?.reference?.out?.Occupation?.length - 1) {
                                    documentsCurriculaGfg.occupationsTable += '<br>';
                                }
                            }
                        });
                    }
                    if (documentsCurriculaGfg?.reference?.out?.Institution?.length > 0
                        || documentsCurriculaGfg?.reference?.in?.Institution?.length > 0) {
                        documentsCurriculaGfg?.reference?.out?.Institution?.forEach((institution, index) => {
                            if (!!institution.nameGerman) {
                                documentsCurriculaGfg.institutionsTable += `- ${institution.nameGerman}`
                                if (index !== documentsCurriculaGfg?.reference?.out?.Institution?.length - 1) {
                                    documentsCurriculaGfg.institutionsTable += '<br>';
                                }
                            }
                        });
                        documentsCurriculaGfg?.reference?.in?.Institution?.forEach((institution, index) => {
                            if (!!institution.nameGerman) {
                                documentsCurriculaGfg.institutionsTable += `- ${institution.nameGerman}`
                                if (index !== documentsCurriculaGfg?.reference?.out?.Institution?.length - 1) {
                                    documentsCurriculaGfg.institutionsTable += '<br>';
                                }
                            }
                        });
                    }
                    const row = `
                    <tr>
                        ${this.getTableEntries(documentsCurriculaGfg, dataArray, tableSearchInput)}
                    </tr>
                 `;
                    tr = HtmlHelper.createElementFromHTML(row);
                    tr.classList.add('pointer');
                    tr.addEventListener('click', () => {
                        ModalDom.openDocumentsCurriculaGfgModal(documentsCurriculaGfg).then();
                    });
                    this.tableBody.appendChild(tr);
                });
            }

            if (dataArray.find(element => element.context === 'responsible-office')) {
                data.forEach((responsibleOffice) => {

                    if (!!responsibleOffice.nameShort && !!responsibleOffice.location) {
                        responsibleOffice.nameShortTable = `${responsibleOffice.nameShort} (${responsibleOffice.location})`;
                    } else {
                        responsibleOffice.nameShortTable = responsibleOffice.nameShort;
                    }

                    if (responsibleOffice.relatedcompetences?.length !== 0) {
                        responsibleOffice.relatedcompetences?.forEach(relatedcompetence => {
                            if (relatedcompetence.states && relatedcompetence.states.length !== 0 && relatedcompetence.competence) {
                                let tempStatesTable = '(';
                                relatedcompetence.states.forEach((state, index) => {
                                    if (state.state && state.state.nameOrigin) {
                                        tempStatesTable += state.state?.nameOrigin;
                                        if (index !== relatedcompetence.states.length - 1) {
                                            tempStatesTable += ', ';
                                        } else {
                                            tempStatesTable += ')';
                                        }
                                    }
                                });
                                // relatedcompetence.competence.nameShortTable = `${relatedcompetence.competence.nameShort}`;
                                relatedcompetence.competence.state = tempStatesTable.replaceAll('(', '').replaceAll(')', '');
                            } else {
                                // relatedcompetence.competence.nameShortTable = `- ${relatedcompetence.competence.nameShort}`;
                            }
                        });
                    }

                    const row = `
                    <tr>
                        ${this.getTableEntries(responsibleOffice, dataArray, tableSearchInput)}
                    </tr>
                 `;
                    tr = HtmlHelper.createElementFromHTML(row);
                    tr.classList.add('pointer');
                    tr.addEventListener('click', () => {
                        ModalDom.openResponsibleOfficeModal(responsibleOffice).then();
                    });
                    this.tableBody.appendChild(tr);
                });
            }
        }
    }

    resetDataTable() {
        this.tableBody.innerHTML = null;
        // this.paginator.tablePagination.innerHTML = null;
        this.page = 1;
        this.offset = 0;
        this.resetDataTableSearchInput();
    }

    resetDataTableSearchInput() {
        this.tableSearchInputResetButton.setAttribute('disabled', 'true');
        this.tableSearchInput.value = '';
        this.searchText = '';
        this.dataModule.getData().then();
    }

}
