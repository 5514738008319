import HttpHelper from '../utils/http-helper';
import Spinner from '../dom/spinner';
import ModalDom from "../dom/modal-dom";

export default class CertificatesModule {

    constructor(countriesModule, certificateGroupModule, type) {
        this.countriesModule = countriesModule;
        // this.certificateGroupModule = certificateGroupModule;
        this.sortOverride = {
            field: 'certificateGroup.nameShort',
            direction: 1
        };
        this.dataTable = { resetDataTable: () => this.getData() };
        this.totalResults = '';
        this.type = type;
        this.groupedViewEl = document.querySelector('#groupedView')

    }

    initialise() {
        this.getData().then( () => new ModalDom());
    }

    async getData() {
        if (!!this.type && (this.type.includes('certificate') || type.includes('certificate-msa'))) {

            this.groupedViewEl.innerHTML = '';
            Spinner.show(this);
            if (this.countriesModule.countriesSelected.size === 0) {
                Spinner.hide(this);
                document.querySelector('#missingCountry').classList.remove('d-none')
                document.querySelector('#emptyDatasetRow').classList.add('d-none')
                document.querySelector('#info-text')?.classList.add('d-none')
                if (document.querySelector('#sub-headline')) document.querySelector('#sub-headline').innerHTML = '';
                return;
            }
            const filterQuery = {
                $and:  [ { $or: Array.from(this.countriesModule.countriesSelected).map((countryId) => ({ 'country': { $oid: countryId } })) } ]
            };

            const response = await HttpHelper.fetchData(`${location.origin}/api/public/${this.type}?filter=${JSON.stringify(filterQuery)}&reference=true`);
            document.querySelector('#emptyDatasetRow').classList.add('d-none')

            document.querySelector('#dataRow').classList.add('d-none')
            document.querySelector('#missingCountry').classList.add('d-none')

            const responseData = response?.data;
            if (!!responseData?.length) {
                document.querySelector('#dataRow').classList.remove('d-none')
                document.querySelector('#info-text')?.classList.remove('d-none')
                if (document.querySelector('#sub-headline')) document.querySelector('#sub-headline').innerHTML = `Schulabschlüsse in ${this.countriesModule.lastCountryNameSelected}`;

                const renderData = responseData
                    .reduce((groups, cert) => {
                        const { certificateGroup, ...certificate } = cert
                        const idx = groups.findIndex(group => group.certificateGroup?._id === certificateGroup?._id)
                        if (idx >= 0) {
                            groups[idx].certificates.push(certificate)
                            groups[idx].certificates.sort((cert1, cert2) => cert1.nameShort >= cert2.nameShort ? 1 : -1)
                        } else {
                            groups.push({
                                certificateGroup,
                                certificates: [ certificate ]
                            })
                        }
                        return groups
                    }, [])
                    .sort((certGroup1, certGroup2) => sorter(true,false)(certGroup1.certificateGroup?.nameShort, certGroup2.certificateGroup?.nameShort))



                const groupedTable = document.createElement('TABLE')
                // const headerRow = document.createElement('TR')
                // const nameHeader = document.createElement("TH");
                // nameHeader.innerHTML = '    '
                // nameHeader.setAttribute('width', '80%')
                // const fromHeader = document.createElement("TH");
                // fromHeader.innerHTML = 'von';
                // fromHeader.setAttribute('width', '10%')
                // const untilHeader = document.createElement("TH");
                // untilHeader.innerHTML = 'bis';
                // untilHeader.setAttribute('width', '10%')
                // headerRow.append(nameHeader, fromHeader, untilHeader)
                // groupedTable.append(headerRow)
                renderData.forEach(group => {
                    const { certificateGroup, certificates } = group
                    const groupRow = document.createElement('TR')
                    groupRow.classList.add('certificateGroup')
                    const groupHeader = document.createElement('TH')
                    groupHeader.innerHTML = certificateGroup?.nameShort ?? '-';
                    groupHeader.setAttribute('width', '80%')
                    // groupHeader.setAttribute('colspan', '3')
                    const fromHeader = document.createElement("TH");
                    fromHeader.innerHTML = 'ab';
                    fromHeader.setAttribute('width', '10%')
                    const untilHeader = document.createElement("TH");
                    untilHeader.innerHTML = 'bis';
                    untilHeader.setAttribute('width', '10%')
                    groupRow.append(groupHeader , fromHeader, untilHeader)
                    groupedTable.append(groupRow)
                    certificates.forEach(cert => {
                        const certRow = this.buildCertTableRow(cert);
                        groupedTable.append(certRow)

                        cert.children?.sort(
                            (cert1, cert2) => cert1.nameShort >= cert2.nameShort ? 1 : -1
                        )
                            .forEach(childCert => {
                                const childCertRow = this.buildCertTableRow(childCert);
                                groupedTable.append(childCertRow)
                            })
                    })
                })
                // this.groupedViewEl.innerHTML = `<pre>${JSON.stringify(renderData, null, 2)}</pre>`
                this.groupedViewEl.append(groupedTable)
            } else {
                document.querySelector('#emptyDatasetRow').classList.remove('d-none')
            }
            // const renderData = [ { context: this.type } ];
            // await this.dataTable.renderTableData(responseData, this.dataTable.tableSearchInput, renderData);
            // this.dataTable.paginator.renderPagination(this.dataTable);

            Spinner.hide(this);
        }
    }

    buildCertTableRow(cert) {
        const certRow = document.createElement('TR')
        certRow.classList.add(cert?.parent ? 'childCertificate' : 'certificate')
        const certNameCell = document.createElement('TD');
        certNameCell.classList.add('certNameCell')
        certNameCell.setAttribute('width', '80%')
        const openLink = document.createElement('A')
        openLink.setAttribute('role', 'button')
        openLink.innerHTML = cert.nameShort
        openLink.addEventListener('click', () => this.openAction(cert))
        certNameCell.append(openLink)
        if (cert.certificateprototypes?.length > 0) {
            const prototypeTag = document.createElement('SPAN')
            prototypeTag.innerHTML = 'Muster'
            prototypeTag.classList.add('p-label--deprecated', 'secret', 'prototype-tag')

            certNameCell.append(prototypeTag)
        }
        const certNameCellTranslation = document.createElement('span')
        certNameCellTranslation.classList.add('certificateTranslation')
        certNameCellTranslation.innerHTML = cert.nameGerman ?? '';
        certNameCell.append(certNameCellTranslation)
        const certFromCell = document.createElement('TD');
        certFromCell.classList.add('certFromCell')
        certFromCell.setAttribute('width', '10%')
        certFromCell.innerHTML = cert.validFrom ?? '';
        const certUntilCell = document.createElement('TD');
        certUntilCell.classList.add('certUntilCell')
        certUntilCell.setAttribute('width', '10%')
        certUntilCell.innerHTML = cert.validUntil ?? '';
        certRow.append(certNameCell, certFromCell, certUntilCell)
        return certRow;
    }

    openAction(cert) {

        if (this.type === 'certificates') {
            if (cert.isChildCertificate) {
                ModalDom.openCertificateChildInformationModal(cert).then();
            }
            if (!!cert.children?.length) {
                ModalDom.openCertificateWithChildrenModal(cert).then();
            } else {
                ModalDom.openCertificateWithoutChildrenModal(cert).then();
            }
        }
        if (this.type === 'certificates-msa') {
            ModalDom.openCertificateMsaModal(cert).then();
        }
    }

    static async getId(id) {
        const response = await HttpHelper.fetchData(`${location.origin}/api/public/certificates/${id}?reference=true`);
        return response?.data;
    }

    static async getCertificateprototypeId(id) {
        const response = await HttpHelper.fetchData(`${location.origin}/api/public/certificateprototype/${id}?reference=true`);
        return response?.data;
    }

    loadOptions() {
        // if (!this.countriesModule.countriesSelected.size || this.countriesModule.countriesSelected.size === 0) {
        // 	this.certificateGroupModule.select.setAttribute('disabled', 'true');
        // 	this.certificateGroupModule.resetButton.setAttribute('disabled', 'true');
        // 	return;
        // }
        //
        // this.certificateGroupModule.loadCertificateGroups();
    }
}

const sorter = (ascending = true, discriminateNull = false) => {
    return function (a,b) {
        if (a === b) {
            return 0;
        }
        if ( a === null || a === undefined) {
            return discriminateNull === true ? 1 : -1
        }
        if ( b === null || b === undefined) {
            return discriminateNull === true ? -1 : 1
        }
        if (ascending === false) {
            return a < b ? 1 : -1;
        } else {
            return a < b ? -1 : 1;
        }
    }
}

