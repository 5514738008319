
import NotificationsDom from '../dom/notifications-dom';
import ModalDom from "../dom/modal-dom";

export default class HtmlHelper {

    static mailformat = /^[a-zA-ZÀ-ž0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-ZÀ-ž0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    static dragElement (element) {
		var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
		if (element.querySelector('header')) {
			// if present, the header is where you move the DIV from:
			element.querySelector('.draggable').onmousedown = dragMouseDown;
		} else {
			// otherwise, move the DIV from anywhere inside the DIV:
			element.onmousedown = dragMouseDown;
		}

		function dragMouseDown(e) {
            ModalDom.reOrder(element.id);
			e = e || window.event;
			e.preventDefault();
			// get the mouse cursor position at startup:
			pos3 = e.clientX;
			pos4 = e.clientY;
			document.onmouseup = closeDragElement;
			// call a function whenever the cursor moves:
			document.onmousemove = elementDrag;
		}

		function elementDrag(e) {
			e = e || window.event;
			e.preventDefault();
			// calculate the new cursor position:
			pos1 = pos3 - e.clientX;
			pos2 = pos4 - e.clientY;
			pos3 = e.clientX;
			pos4 = e.clientY;
			// set the element's new position:
			// element.style.top = (element.offsetTop - pos2) + 'px';
			// element.style.left = (element.offsetLeft - pos1) + 'px';
            if(element.offsetTop - pos2 >= 0 &&
                element.offsetLeft - pos1 >= 0 &&
                element.offsetTop - pos2 <= window.innerHeight - element.offsetHeight &&
                element.offsetLeft - pos1 <= window.innerWidth - element.offsetWidth) {
                element.style.top = (element.offsetTop - pos2) + 'px';
                element.style.left = (element.offsetLeft - pos1) + 'px';
            }
		}

		function closeDragElement() {
			// stop moving when mouse button is released:
			document.onmouseup = null;
			document.onmousemove = null;
		}
	}

	static createElementFromHTML (htmlString) {

		let el = htmlString.includes('<tr>') ? document.createElement('tbody') : document.createElement('div');
		el.innerHTML = htmlString.trim();
		return el.firstChild;
	}

	static changeSort (sort, header) {
		const fieldname = header.dataset['overridesortname'] ? header.dataset['overridesortname'] : header.dataset['fieldname'];
        const direction = header.getAttribute('aria-sort');
		if (!fieldname) {
			throw {
				message: `Element ${header} missing attribute "data-fieldname"`,
				element: header
			};
		}
		if (!direction) {
			throw {
				message: `Element ${header} missing attribute "aria-sort"`,
				element: header
			};
		}
		if (fieldname === sort.field) {
			if (direction === 'none') {
				header.setAttribute('aria-sort', 'ascending');
				sort = {
					field: fieldname,
					direction: 1
				};
			} else {
				if (direction === 'ascending') {
					header.setAttribute('aria-sort', 'descending');
					sort = {
						field: fieldname,
						direction: -1
					};
				} else {
					header.setAttribute('aria-sort', 'ascending');
					sort = {
						field: fieldname,
						direction: 1
					};
				}
			}

		} else {
            document.querySelector('th:not([aria-sort=none])')?.setAttribute('aria-sort', 'none');
            header.setAttribute('aria-sort', 'ascending');
            sort = {
            	field: fieldname,
            	direction: 1
            };
		}

		return sort;
	}

	static domHighlightString (value, tableSearchInput) {



		if (!tableSearchInput) {
			tableSearchInput = { value: '' };
		}
		let newVal = '';

		if (!!value && tableSearchInput.value !== '') {
			const searchArray = tableSearchInput.value.toString().split(' ');
			searchArray.some(searchEntry => {
				if (searchEntry) {
					const searchRegex = new RegExp('(' + searchEntry.toString() + ')', 'ig');
					if(newVal === '') {
                        newVal += value.toString().replaceAll(searchRegex, '<span class="highlight">$1</span>');
                    } else {
                        newVal = newVal.toString().replaceAll(searchRegex, '<span class="highlight">$1</span>');
                    }


				}
			});
            return newVal;

        } else if (value) {
			return value.toString();
		} else {
			return '';
		}
	}

	static validateEmailForm(inputElement) {
		if (inputElement.value !== '' && !!HtmlHelper.validateEmail(inputElement.value)) {
			inputElement.classList.remove('invalid-form');
		} else {
			inputElement.classList.add('invalid-form');
		}
	}

	static validateInputForm(inputElement) {
		if (inputElement.hasAttribute('required')) {
			if (inputElement.getAttribute('required') !== '') {
				if (inputElement.value !== '' ) {
					inputElement.classList.remove('invalid-form');
				} else {
					inputElement.classList.add('invalid-form');
				}
			}
		}
	}

	static validateSelectForm(selectElement) {
		if (selectElement.hasAttribute('required')) {
            if (!!selectElement.value) {
				selectElement.classList.remove('invalid-form');
			} else {
				selectElement.classList.add('invalid-form');
			}
		}
	}

	static validateCheckbox(checkbox, header) {
		if (checkbox.hasAttribute('required')) {
			if (checkbox.checked) {
				header.innerHTML = 'Aufgabengebiet(e) *';
				header.classList.remove('invalid-form');
			}
		}
	}

	static validateCheckboxGroup(checkboxes, header) {
		if(checkboxes.length !== 0) {
			let invalidCounter = 0;
			let requiredCheckboxes = 0;
			let invalidForm = false;

			checkboxes.forEach(checkbox => {
				if (checkbox.hasAttribute('required')) {
					requiredCheckboxes++;
					if (!checkbox.checked) {
						invalidCounter++;
					}
				}
			});
			if(requiredCheckboxes === invalidCounter) {
				invalidForm = true;
			}
			if (invalidForm) {
				header.innerHTML = 'Aufgabengebiet(e) *<br>Bitte mindestens ein Aufgabengebiet auswählen!';
				header.classList.add('invalid-form');
			} else {
				header.innerHTML = 'Aufgabengebiet(e) *';
				header.classList.remove('invalid-form');
			}
		}
	}

	static validateEmail(inputText) {
		if(inputText.match(HtmlHelper.mailformat)) {
			return true;
		} else {
			NotificationsDom.renderCautionNotification('Bitte geben Sie eine E-Mail-Adresse an!');
			return false;
		}
	}

	static markAsSecret(element, nodeType) {
	    if(!element.querySelector('#secret')) {
            switch (nodeType) {
                case 'parent':
                    // element.parentElement.classList.add('secret');
                    element.parentElement.appendChild(HtmlHelper.createElementFromHTML('<i id="secret" class="p-icon--lock-unlock secret" title="vertraulich"></i>'));
                    break;
                case 'child':
                    // element.firstElementChild.classList.add('secret');
                    element.firstElementChild.appendChild(HtmlHelper.createElementFromHTML('<i id="secret" class="p-icon--lock-unlock secret" title="vertraulich"></i>'));
                    break;
                case 'self':
                    // element.classList.add('secret');
                    element.appendChild(HtmlHelper.createElementFromHTML('<i id="secret" class="p-icon--lock-unlock secret" title="vertraulich"></i>'));
                    break;
            }
        }
	}

	static resetCssAnimation(targetElement, cssClass) {
		targetElement.style.animation = 'none';
		targetElement.offsetHeight; /* trigger reflow */
		targetElement.style.animation = null;
		targetElement.classList.add(cssClass);
	}

	static initialiseHiddenMails() {
	    const hiddenMails = document.querySelectorAll('.hiddenMail');
	    if(hiddenMails && hiddenMails.length > 0) {
	        hiddenMails.forEach(hiddenMail => {
	            hiddenMail.addEventListener('click', function addClick(event) {
                    event.preventDefault();
                    hiddenMail.removeEventListener('click', addClick, false);
                    const email = hiddenMail.attributes?.getNamedItem("data-email")?.value?.toLowerCase()?.replace('\(at\)','@').replace('\(dot\)','.');
                    if(email) {
                        // hiddenMail.classList.remove('hiddenMail');
                        hiddenMail.innerHTML = email;
                        const href = document.createAttribute('href');
                        href.value = `mailto:${email}`;
                        hiddenMail.attributes.setNamedItem(href);
                    }
                });
            });
        }
    }

    static hideEmail(inputText) {
        if(inputText?.match(HtmlHelper.mailformat)) {
            return inputText?.replace('@','(AT)').replace('.','(DOT)');
        } else {
            return inputText;
        }
    }

    static isLink(str) {
        return /^http(s)?:\/\//.test(str)
    }
    static isEmail(inputText) {
        if(typeof inputText === 'string') {
            if (!!inputText?.match(HtmlHelper.mailformat)) {
                return true;
            }
            if (!!inputText?.replace('\(AT\)','@').replace('\(DOT\)','.').match(HtmlHelper.mailformat)) {
                return true;
            }
        }
        return false;
    }

}
