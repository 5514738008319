import DataTableDom from '../dom/data-table-dom';
import NotificationsDom from '../dom/notifications-dom';
import HttpHelper from '../utils/http-helper';
import Spinner from '../dom/spinner';

export default class ResponsibleOfficeModule {

    constructor() {
        this.staticStateModule = null;
        this.competencesModule = null;
        this.dataTable = null;
        this.totalResults = '';
    }

    initialise(staticStateModule, competencesModule) {
        this.staticStateModule = staticStateModule;
        this.competencesModule = competencesModule;
        this.dataTable = new DataTableDom(this);
    }

    async getData() {
        Spinner.show(this);

        const searchInput = document.getElementById('tableSearchInput');
        const competencesSelect = document.getElementById('competencesSelect');
        const regionSelect = document.getElementById('regionSelect');

        if (competencesSelect.value === "allCompetences" && regionSelect.value === "" && searchInput.value === "") {
            Spinner.hide(this);
            document.querySelector('#missingCountry').classList.remove('d-none')
            document.querySelector('#emptyDatasetRow').classList.add('d-none')
            document.querySelector('#info-text')?.classList.add('d-none')
            document.querySelector('#dataRow').classList.add('d-none')
            if (document.querySelector('#sub-headline')) document.querySelector('#sub-headline').innerHTML = '';
            return;
        }

        let filterQuery = {
            $and: []
        };

        if (!!this.staticStateModule.regionsSelected) {
            filterQuery['$and'] = [{
                $or: [
                    {'relatedcompetences.states.state': {$oid: this.staticStateModule.regionsSelected}},
                    {'relatedcompetences.states.state.all': true}
                ]
            }];
        }

        if (this.competencesModule.competencesSelected !== 'allCompetences') {
            filterQuery['$and'].push({'relatedcompetences.competence': {$oid: this.competencesModule.competencesSelected}});
        }

        if (filterQuery['$and'].length === 0) {
            filterQuery = {};
        }
        const response = await HttpHelper.fetchData(`${location.origin}/api/public/responsible-office?sort={"${this.dataTable.sort.field}":${this.dataTable.sort.direction}}&filter=${JSON.stringify(filterQuery)}&limit=${this.dataTable.limit}&offset=${this.dataTable.offset}&search=${this.dataTable.searchText}&reference=true`);
        document.querySelector('#emptyDatasetRow').classList.add('d-none')

        document.querySelector('#dataRow').classList.add('d-none')
        document.querySelector('#missingCountry').classList.add('d-none')

        this.totalResults = response?.headers['total-results'] ?? '0';
        this.dataTable.totalResults = this.totalResults;
        if (this.totalResults === '0') {
            NotificationsDom.renderInformationNotification('Für diese Auswahl wurden keine Datensätze gefunden!');
            document.querySelector('#info-text')?.classList.remove('d-none');
            this.dataTable.emptyDatasetRow.classList.remove('d-none');
            if (this.dataTable.tableSearchInput.value === '') {
                this.dataTable.dataRow.classList.add('d-none');
            }
        } else {
            document.querySelector('#info-text')?.classList.remove('d-none');
            this.dataTable.emptyDatasetRow.classList.add('d-none');
            this.dataTable.dataRow.classList.remove('d-none');
        }
        const responseData = response?.data;
        const renderData = [{context: 'responsible-office'}];
        await this.dataTable.renderTableData(responseData, this.dataTable.tableSearchInput, renderData);
        this.dataTable.paginator.renderPagination(this.dataTable);

        Spinner.hide(this);
    }

    static async getId(id) {
        const response = await HttpHelper.fetchData(`${location.origin}/api/public/responsible-office/${id}`);
        return response?.data;
    }

    loadOptions() {
        if (!this.staticStateModule.regionsSelected.size || this.staticStateModule.regionsSelected.has('')) {
            this.competencesModule.select.setAttribute('disabled', 'true');
            this.competencesModule.resetButton.setAttribute('disabled', 'true');
            this.competencesModule.setAllCompetencesOption();
            return;
        }

        this.competencesModule.loadCompetences();
    }
}
