import NotificationsDom from '../dom/notifications-dom';
import DataTableDom from '../dom/data-table-dom';
import HttpHelper from '../utils/http-helper';
import Spinner from '../dom/spinner';

export default class DegreeTypesModule {

	constructor(countriesModule) {
		this.countriesModule = countriesModule;

		this.dataTable = null;
		this.totalResults = '';

		this.degreeTypes = [];
		this.degreeTypeSelected = null;
		this.select = document.querySelector('#degreeTypeSelect');
		this.resetButton = document.querySelector('#degreeTypeResetButton');

	}

	initialise() {
		this.dataTable = new DataTableDom(this);
	}

	addEventListener(degreesModule) {
		this.resetButton.addEventListener('click', () => {
			this.resetSelect().then(degreesModule.getData());
		});
		this.select.addEventListener('change', () => {
			this.degreeTypeSelected = this.select.value;
			if (this.select.value === '') {
				this.resetButton.setAttribute('disabled', 'true');
			} else {
				this.resetButton.removeAttribute('disabled');
			}
            if (degreesModule.dataTable) degreesModule.dataTable.page = 1;
            if (degreesModule.dataTable) degreesModule.dataTable.offset = 0;
            degreesModule.getData().then();
		});
	}

	async getData () {
		Spinner.show(this);

        const searchInput = document.getElementById('tableSearchInput');

        if (this.countriesModule.countriesSelected.size === 0 && searchInput.value === "") {
            Spinner.hide(this);
            document.querySelector('#missingCountry').classList.remove('d-none')
            document.querySelector('#emptyDatasetRow').classList.add('d-none')
            document.querySelector('#info-text')?.classList.add('d-none')
            document.querySelector('#dataRow').classList.add('d-none')
            if (document.querySelector('#sub-headline')) document.querySelector('#sub-headline').innerHTML = '';
            return;
        }

		let filterQuery = {
			$and: []
		};

        if (this.countriesModule.countriesSelected.size === 0) {
            filterQuery = {};
        } else {
            filterQuery['$and'] = [ { $or: Array.from(this.countriesModule.countriesSelected).map((countryId) => ({ 'country': { $oid: countryId } })) } ];
        }
		const response = await HttpHelper.fetchData(`${location.origin}/api/public/degree-type?sort={"${this.dataTable.sort.field}":${this.dataTable.sort.direction}}&filter=${JSON.stringify(filterQuery)}&limit=${this.dataTable.limit}&offset=${this.dataTable.offset}&search=${this.dataTable.searchText}&reference=true`);
        document.querySelector('#emptyDatasetRow').classList.add('d-none')

        document.querySelector('#dataRow').classList.add('d-none')
        document.querySelector('#missingCountry').classList.add('d-none')

        this.totalResults = response?.headers['total-results'] ?? '0';
        this.dataTable.totalResults = this.totalResults;
        if (this.totalResults === '0') {
            NotificationsDom.renderInformationNotification('Für diese Auswahl wurden keine Datensätze gefunden!');
            document.querySelector('#info-text')?.classList.remove('d-none');
            this.dataTable.emptyDatasetRow.classList.remove('d-none');
            if(this.dataTable.tableSearchInput.value === '') {
                this.dataTable.dataRow.classList.add('d-none');
            }
        } else {
            document.querySelector('#info-text')?.classList.remove('d-none');
            this.dataTable.emptyDatasetRow.classList.add('d-none');
            this.dataTable.dataRow.classList.remove('d-none');
        }
        const responseData = response?.data;
        const renderData = [ { context: 'degree-type' } ];
        this.dataTable.renderTableData(responseData, this.dataTable.tableSearchInput, renderData);
        this.dataTable.paginator.renderPagination(this.dataTable);

		Spinner.hide(this);
	}

	async getAllData (countriesSelected) {
		if (countriesSelected.size === 0) {
			return;
		}
		const filterQuery = `{"$or": [${Array.from(countriesSelected).map((countryId) => {
			if (!countryId) {
				return '';
			} else {
				return JSON.stringify({ 'country': { $oid: countryId } });
			}
		}).join()}]}`;
		const response = await HttpHelper.fetchData(`${location.origin}/api/public/degree-type?sort={"nameShort":1}&filter=${filterQuery}`);
		this.degreeTypes = response?.data;
	}

	static async getId(id) {
		const response = await HttpHelper.fetchData(`${location.origin}/api/public/degree-type/${id}?reference=true`);
		const json = response?.data;
		return json;
	}

	async resetSelect () {
		this.setAllDegreeTypesOption();

        if (this.dataTable) this.dataTable.page = 1;
        if (this.dataTable) this.dataTable.offset = 0;
        // loadOptions();
        if (this.countriesModule.countriesSelected.size === 0) {
        	return;
        }
        if (this.countriesModule.countriesSelected.size > 0) {
        	this.loadDegreeTypes();
        }
	}

	setAllDegreeTypesOption () {
		this.select.value = '';
		this.select.innerHTML = null;
		this.degreeTypeSelected = null;
		this.resetButton.setAttribute('disabled', 'true');
		if (this.countriesModule.countriesSelected.size === 0 || this.countriesModule.countriesSelected.size === 0) {
			const defaultOption = document.createElement('OPTION');
			defaultOption.innerHTML = 'Bitte wählen Sie zunächst ein Land';
			defaultOption.value = '';
			this.select.appendChild(defaultOption);
			this.select.setAttribute('disabled', 'true');
		} else {
			const defaultOption = document.createElement('OPTION');
			defaultOption.innerHTML = 'Alle Abschlusstypen';
			defaultOption.value = '';
			this.select.appendChild(defaultOption);
		}
	}

	loadDegreeTypes () {
		this.setAllDegreeTypesOption();
		this.getAllData(this.countriesModule.countriesSelected).then(() => {

			if (this.degreeTypes.length > 0) {
				this.degreeTypes.forEach((degreeType) => {
					const newOption = document.createElement('OPTION');
					newOption.innerHTML = degreeType.nameShort;
					newOption.value = degreeType._id;
					this.select.appendChild(newOption);
				});

				this.select.value = '';
				this.select.removeAttribute('disabled');
			}
		});
	}

	loadOptions () {
		if (!this.countriesModule.countriesSelected.size || this.countriesModule.countriesSelected.size === 0) {
			return;
		}
	}
}

