import HttpHelper from '../utils/http-helper';

export default class QualificationsModule {

    static async getId(id) {
        const response = await HttpHelper.fetchData(`${location.origin}/api/public/qualification/${id}/?reference=true`);
        return response?.data;
    }

}
