
import DataTableDom from '../dom/data-table-dom';
import NotificationsDom from '../dom/notifications-dom';
import HttpHelper from '../utils/http-helper';
import Spinner from '../dom/spinner';
import TokenHandler from "../utils/token-handler";

export default class OccupationsModule {

	constructor(countriesModule) {
		this.countriesModule = countriesModule;

		this.dataTable = null;
		this.totalResults = '';
        this.checkboxGfg = document.querySelector('#checkboxGfg');

        if (!!TokenHandler.isGfg()) {
            this.checkboxGfg.parentElement.parentElement.classList.remove('d-none')
        }

    }

	initialise() {
		this.dataTable = new DataTableDom(this);
        //https://beige-unimaginable-idols.awork.io/tasks/702948fc-c96e-ec11-94f6-dc984040d6c7/details
        this.checkboxGfg.checked = TokenHandler.isGfg();
	}

	addEventListener() {
        this.checkboxGfg.addEventListener('change', () => {
            if (this.dataTable) this.dataTable.page = 1;
            if (this.dataTable) this.dataTable.offset = 0;
            this.getData().then();
        });
	}

	async getData () {
        Spinner.show(this);

        const searchInput = document.getElementById('tableSearchInput');

        if (this.countriesModule.countriesSelected.size === 0 && searchInput.value === "") {
            Spinner.hide(this);
            document.querySelector('#missingCountry').classList.remove('d-none')
            document.querySelector('#emptyDatasetRow').classList.add('d-none')
            document.querySelector('#info-text')?.classList.add('d-none')
            document.querySelector('#dataRow').classList.add('d-none')
            if (document.querySelector('#sub-headline')) document.querySelector('#sub-headline').innerHTML = '';
            return;
        }

        const gfgFilter = this.checkboxGfg.checked ? {gfg: true} : {};
        let filterQuery = gfgFilter ?? {};
        if (this.countriesModule.countriesSelected.size !== 0) {
            filterQuery = {
                $and: []
            };
            filterQuery['$and'] = [gfgFilter, {$or: Array.from(this.countriesModule.countriesSelected).map((countryId) => ({'country': {$oid: countryId}}))}];
        }
        const response = await HttpHelper.fetchData(`${location.origin}/api/public/occupation?sort={"${this.dataTable.sort.field}":${this.dataTable.sort.direction}}&filter=${JSON.stringify(filterQuery)}&limit=${this.dataTable.limit}&offset=${this.dataTable.offset}&search=${this.dataTable.searchText}&reference=true`);
        document.querySelector('#emptyDatasetRow').classList.add('d-none')

        document.querySelector('#dataRow').classList.add('d-none')
        document.querySelector('#missingCountry').classList.add('d-none')

        this.totalResults = response?.headers['total-results'] ?? '0';
        this.dataTable.totalResults = this.totalResults;
        if (this.totalResults === '0') {
            NotificationsDom.renderInformationNotification('Für diese Auswahl wurden keine Datensätze gefunden!');
            document.querySelector('#info-text')?.classList.remove('d-none');
            this.dataTable.emptyDatasetRow.classList.remove('d-none');
            if (this.dataTable.tableSearchInput.value === '') {
                this.dataTable.dataRow.classList.add('d-none');
            }
        } else {
            document.querySelector('#info-text')?.classList.remove('d-none');
            this.dataTable.emptyDatasetRow.classList.add('d-none');
            this.dataTable.dataRow.classList.remove('d-none');
        }
        const responseData = response?.data;
        const renderData = [{context: 'occupation'}];
        this.dataTable.renderTableData(responseData, this.dataTable.tableSearchInput, renderData);
        this.dataTable.paginator.renderPagination(this.dataTable);

        Spinner.hide(this);
	}

	static async getId(id) {
		const response = await HttpHelper.fetchData(`${location.origin}/api/public/occupation/${id}?reference=true`);
		return response?.data;
	}

	loadOptions () {
		if (!this.countriesModule.countriesSelected.size || this.countriesModule.countriesSelected.size === 0) {
			return;
		}
	}
}
