
export default class Spinner {

	static show(context){
		const spinner = document.querySelector('.spinner');
		if(!!context?.totalResults) {
			context?.dataTable?.dataTable?.classList.add('d-none');
		}

		spinner.classList.remove('d-none');
	}

	static hide(context){
		const spinner = document.querySelector('.spinner');
		context?.dataTable?.dataTable?.classList.remove('d-none');

		spinner.classList.add('d-none');
	}
}
