import TokenHandler from '../../utils/token-handler';
import HtmlHelper from "../../utils/html-helper";

class Main {
	constructor() {
		TokenHandler.validateLoginState();
        this.error404 = document.getElementById('error404');
	}

	run() {
		this.initializeModules();
        this.setError404Headers();
	}

	initializeModules() {
        HtmlHelper.initialiseHiddenMails();

        //add tooltip to external links
        window.addEventListener('load', function () {
            const externalLinks = document.querySelectorAll('a[href^=http]');
            if(externalLinks) {
                externalLinks.forEach(externalLink => {
                    externalLink.setAttribute('title', 'Link zu externer Webseite. Es öffnet sich ein neues Browserfenster.')
                });
            }
        })
	}

    setError404Headers() {
        if (!!this.error404) {
            document.title = '404: Seite nicht gefunden - anabin: Infoportal zu ausländischen Bildungsabschlüssen';
            document.head.innerHTML += '<meta name="robots" content="noindex">';
        }
    }

}
const main = new Main();
main.run();


// /**
//  Toggles the expanded/collapsed classed on side navigation element.
//
//  @param {HTMLElement} sideNavigation The side navigation element.
//  @param {Boolean} show Whether to show or hide the drawer.
//  */
// function toggleDrawer(sideNavigation, show) {
//     console.warn(sideNavigation, show)
//     if (sideNavigation) {
//         if (show) {
//             sideNavigation.classList.remove('is-collapsed');
//             sideNavigation.classList.add('is-expanded');
//         } else {
//             sideNavigation.classList.remove('is-expanded');
//             sideNavigation.classList.add('is-collapsed');
//         }
//     }
// }
//
// /**
//  Attaches event listeners for the side navigation toggles
//  @param {HTMLElement} sideNavigation The side navigation element.
//  */
// function setupSideNavigation(sideNavigation) {
//     var toggles = [].slice.call(sideNavigation.querySelectorAll('.js-drawer-toggle'));
//
//     toggles.forEach(function (toggle) {
//         toggle.addEventListener('click', function (event) {
//             event.preventDefault();
//             var sideNav = document.getElementById(toggle.getAttribute('aria-controls'));
//
//             if (sideNav) {
//                 toggleDrawer(sideNav, !sideNav.classList.contains('is-expanded'));
//             }
//         });
//     });
// }
//
// /**
//  Attaches event listeners for all the side navigations in the document.
//  @param {String} sideNavigationSelector The CSS selector matching side navigation elements.
//  */
// function setupSideNavigations(sideNavigationSelector) {
//     // Setup all side navigations on the page.
//     var sideNavigations = [].slice.call(document.querySelectorAll(sideNavigationSelector));
//
//     sideNavigations.forEach(setupSideNavigation);
// }
//
// setupSideNavigations('#drawer');


/**
 Toggles visibility of given subnav by toggling is-active class to it
 and setting aria-hidden attribute on dropdown contents.
 @param {HTMLElement} subnav Root element of subnavigation to open.
 @param {Boolean} open indicate whether we want to open or close the subnav.
 */
function toggleSubnav(subnav, open) {
    if (open) {
        subnav.classList.add('is-active');
    } else {
        subnav.classList.remove('is-active');
    }

    var toggle = subnav.querySelector('.p-subnav__toggle');

    if (toggle) {
        var dropdown = document.getElementById(toggle.getAttribute('aria-controls'));

        if (dropdown) {
            dropdown.setAttribute('aria-hidden', open ? false : true);
        }
    }
}

/**
 Closes all subnavs on the page.
 */
function closeAllSubnavs() {
    var subnavs = document.querySelectorAll('.p-subnav');
    for (var i = 0, l = subnavs.length; i < l; i++) {
        toggleSubnav(subnavs[i], false);
    }
}

/**
 Attaches click event listener to subnav toggle.
 @param {HTMLElement} subnavToggle Toggle element of subnavigation.
 */
function setupSubnavToggle(subnavToggle) {
    subnavToggle.addEventListener('click', function (event) {

        event.preventDefault();
        event.stopPropagation();
        if (!TokenHandler.getUsername()) {
            window.location.replace(`${location.origin}/db/login`);
        }

        var subnav = subnavToggle.parentElement;
        var isActive = subnav.classList.contains('is-active');

        closeAllSubnavs();
        if (!isActive) {
            toggleSubnav(subnav, true);
        }
    });
}

// Setup all subnav toggles on the page
var subnavToggles = document.querySelectorAll('.p-subnav__toggle');

for (var i = 0, l = subnavToggles.length; i < l; i++) {
    setupSubnavToggle(subnavToggles[i]);
}

// Close all menus if anything else on the page is clicked
document.addEventListener('click', function (event) {
    var target = event.target;

    if (target.closest) {
        if (!target.closest('.p-subnav__toggle') && !target.closest('.p-subnav__item')) {
            closeAllSubnavs();
        }
    } else if (target.msMatchesSelector) {
        // IE friendly `Element.closest` equivalent
        // as in https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
        do {
            if (target.msMatchesSelector('.p-subnav__toggle') || target.msMatchesSelector('.p-subnav__item')) {
                return;
            }
            target = target.parentElement || target.parentNode;
        } while (target !== null && target.nodeType === 1);

        closeAllSubnavs();
    }
});
