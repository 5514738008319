
function arraySortAlphabetically(array) {
    return array.sort((a, b) => a.localeCompare(b))
}

function getFunctionByName(functionName, context) {
	// If using Node.js, the context will be an empty object
	if(typeof(window) == 'undefined') {
		context = context || global;
	}else{
		// Use the window (from browser) as context if none providen.
		context = context || window;
	}

	// Retrieve the namespaces of the function you want to execute
	// e.g Namespaces of "MyLib.UI.alerti" would be ["MyLib","UI"]

	var namespaces = functionName.split('.');

	// Retrieve the real name of the function i.e alerti
	var functionToExecute = namespaces.pop();

	// Iterate through every namespace to access the one that has the function
	// you want to execute. For example with the alert fn "MyLib.UI.SomeSub.alert"
	// Loop until context will be equal to SomeSub
	for (var i = 0; i < namespaces.length; i++) {
		context = context[namespaces[i]];
	}

	// If the context really exists (namespaces), return the function or property
	if(context){
		return context[functionToExecute];
	}else{
		return undefined;
	}
}

class DatatypeHelper {

	static getNestedJsonObjectByString (o, s) {

		if(typeof s === 'string') {

			s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
			s = s.replace(/^\./, '');           // strip a leading dot
			let a = s.split('.');
			for (let i = 0, n = a.length; i < n; ++i) {
				let k = a[i];

				if  (!!o && o instanceof Object) {
					if (k in o) {
						o = o[k];
					} else {
						return;
					}
				}
			}
		}
		return o;
	}

	static /**
     * Returns the function that you want to execute through its name.
     * It returns undefined if the function || property doesn't exists
     *
     * @param functionName {String}
     * @param context {Object || null}
     */
	getFunctionByName(functionName, context) {
		// If using Node.js, the context will be an empty object
		if(typeof(window) == 'undefined') {
			context = context || global;
		}else{
			// Use the window (from browser) as context if none providen.
			context = context || window;
		}

		// Retrieve the namespaces of the function you want to execute
		// e.g Namespaces of "MyLib.UI.alerti" would be ["MyLib","UI"]

		var namespaces = functionName.split('.');

		// Retrieve the real name of the function i.e alerti
		var functionToExecute = namespaces.pop();

		// Iterate through every namespace to access the one that has the function
		// you want to execute. For example with the alert fn "MyLib.UI.SomeSub.alert"
		// Loop until context will be equal to SomeSub
		for (var i = 0; i < namespaces.length; i++) {
			context = context[namespaces[i]];
		}

		// If the context really exists (namespaces), return the function or property
		if(context){
			return context[functionToExecute];
		}else{
			return undefined;
		}
	}

	static getStackTrace (caller) {
		let stack = new Error().stack || '';
		stack = stack.split('\n').map(function (line) {
			return line.trim();
		});
		const trace = stack.splice(stack[0] == 'Error' ? 2 : 1);

		return trace.find(element => element.includes(caller));
	}

	static arrayRemove(arr, value) {
		return arr.filter(function(ele){
			return ele != value;
		});
	}

	static formattedDate(inputFormat) {
	    if (!inputFormat) return;
	    if(inputFormat.toString().length === 4) { //return if inputFormat is year
	        return inputFormat;
        }
		function pad(s) { return (s < 10) ? '0' + s : s; }
		let d = new Date(inputFormat);
		return [ pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear() ].join('.');
	}

	static getYearFromDate(inputDate) {
        const date = new Date(inputDate);
        return date.getFullYear();
    }

	static getDateToday() {
		let today = new Date();
		let dd = today.getDate();

		let mm = today.getMonth()+1;
		let yyyy = today.getFullYear();
		if(dd<10)
		{
			dd='0'+dd;
		}

		if(mm<10)
		{
			mm='0'+mm;
		}

		return dd+'.'+mm+'.'+yyyy;
	}

    static addUnit(object, property, unit) {
	    if(!!object) {
            if(object?.hasOwnProperty(property) && object[property] !== null) {
                object[property] = `${object[property]} ${unit}`;
            }
        }
    }
}
export { getFunctionByName, DatatypeHelper, arraySortAlphabetically };
